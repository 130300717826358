<template>
  <div class="navbar">
    <div class="navbar-wrapper">
      <div class="navbar-logo">
      </div>
      <div class="navbar-links" v-if="$store.state.user.email">
        <router-link style="text-decoration: none;margin-right: 20px" v-if="$store.state.user.cart.items.length" to="/cart">Корзина ({{$store.state.user.cart.items.length}})</router-link>
        <div class="navbar-email">{{ $store.state.user.email }}</div>
        <div class="exit">
          <svg-icon
              :path="mdiExitToApp"
              type="mdi"
              size="22"
              @click="$server.logout()"
          />
        </div>
      </div>
      <div class="navbar-links" v-else>
        <div class="navbar-email" style="cursor:pointer" @click="$eventBus.emit('open-auth-modal')">Личный кабинет</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mdiExitToApp} from '@mdi/js'

export default {
  name: "Navbar",
  data() {
    return {
      mdiExitToApp
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  position: absolute;
  top: 0;
  z-index: 1;
  display: flex;
  flex-grow: 1;
  width: 100%;
  margin: 0 auto;
  align-items: center;

  &-wrapper {
    height: var(--navbar-height);
    background: transparent;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .navbar-logo {
      width: 32px;
    }

    .navbar-links {
      display: flex;
      align-items: center;
      * {
        color: white;
      }

      .navbar-email {
        margin-right: 8px;
      }

      .exit {
        cursor: pointer;
      }
    }
  }
}
</style>
