export class Server {
  constructor() {
    this.domain = window.location.host.includes('localhost:') ? 'annasudneko.pro' : window.location.host;
    this.url = `https://${this.domain}/api/`;
    this.token = this.getToken();
    this.version = 2;
  }

  getToken() {
    return localStorage.api_token;
  }

  setToken(token) {
    localStorage.api_token = token;
    this.token = token;
  }

  logout() {
    if (!localStorage.api_token) return;
    localStorage.api_token = '';
    this.request('auth/logout', {})
    window.open('/', '_self')
  }

  serialize(obj, prefix) {
    var str = [];
    for (var p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        var k = prefix ? prefix + '[' + p + ']' : p,
          v = obj[p];
        str.push(typeof v == 'object' ? this.serialize(v, k) : encodeURIComponent(k) + '=' + encodeURIComponent(v));
      }
    }
    return str.join('&');
  }

  getInitialFormData() {
    let formData = new FormData()
    if (this.token) formData.append('token', this.token);
    formData.append('version', this.version);
    return formData;
  }

  request(method, data = {}, onSuccess, onError, onFinally) {
    let formData = this.getInitialFormData();
    for (let i in data) {
      formData.append(i, typeof data[i] === 'object' && i !== 'file' ? JSON.stringify(data[i]) : data[i]);
    }

    const checkForError = (response) => {
      if (!response.ok) throw Error(response.statusText);
      return response.json();
    };

    return fetch(`${this.url + method}`, {
      method: "POST",
      body: formData,
      headers: {
        Accept: "application/json",
      },
    })
      .then(checkForError)
      .then((response) => {
        if (!response.success && response.error === 'user is not logged in') {
          this.logout();
          // window.location.reload()
          return;
        }
        if (!response.success && typeof onError === 'function') return onError(response);
        if (typeof onSuccess === 'function') onSuccess(response);
        return response;
      })
      .catch((error) => {
        if (typeof onError === 'function') onError(error);
        console.log(error)
        return error;
      })
      .finally(() => {
        if (typeof onFinally === 'function') onFinally();
      });
  }
}
