import * as THREE from "three";

window.THREE = THREE;
require("three/examples/js/controls/OrbitControls.js");

let OrbitControls = THREE.OrbitControls;
// export default THREE;
export {OrbitControls};

import CANNON from "cannon";
import {DiceManager} from "threejs-dice/lib/dice";
import Stats from "stats.js";
import {DiceD2, DiceD4_} from "@/Dice2";
import {DiceD6, DiceD12, DiceD4, DiceD8, DiceD10, DiceD20} from "threejs-dice";

// standard global variables
var container,
  scene,
  camera,
  renderer,
  controls,
  stats,
  world,
  dice = [];

// init();

var die = false;
let diceNum = 0;
// FUNCTIONS
scene = new THREE.Scene();

function setDie(dn) {
  diceNum = dn;
  let options = {size: 3, backColor: '#24408e', fontColor: '#ffffff'}
  if (diceNum == 'Да/нет') die = new DiceD2(options);
  else if (diceNum == 4) die = new DiceD4_(options);
  else if (diceNum == 8) die = new DiceD8(options);
  else if (diceNum == 10) die = new DiceD10(options);
  else if (diceNum == 12) die = new DiceD12(options);
  else if (diceNum == 20) die = new DiceD20(options);
  else die = new DiceD6(options);
}

export function init(diceNum = 6) {
  // SCENE
  // CAMERA
  while (scene.children.length > 0) {
    scene.remove(scene.children[0]);
  }
  if (die !== false) {
    scene.remove(die);
    setDie(diceNum);
    return;
  }
  var SCREEN_WIDTH = window.innerWidth,
    SCREEN_HEIGHT = window.innerHeight;
  var VIEW_ANGLE = 45,
    ASPECT = SCREEN_WIDTH / SCREEN_HEIGHT,
    NEAR = 0.01,
    FAR = 20000;
  camera = new THREE.PerspectiveCamera(VIEW_ANGLE, ASPECT, NEAR, FAR);
  scene.add(camera);
  camera.position.set(0, 30, 30);
  // RENDERER
  renderer = new THREE.WebGLRenderer({antialias: true});
  renderer.setSize(SCREEN_WIDTH, SCREEN_HEIGHT);
  renderer.shadowMap.enabled = true;
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;

  container = document.getElementById("ThreeJS");
  container.appendChild(renderer.domElement);
  // EVENTS
  // CONTROLS
  controls = new OrbitControls(camera, renderer.domElement);
  // STATS
  stats = new Stats();
  stats.domElement.style.position = "absolute";
  stats.domElement.style.bottom = "0px";
  stats.domElement.style.zIndex = 100;
  // container.appendChild(stats.domElement);

  let ambient = new THREE.AmbientLight("#ffffff", 0.3);
  scene.add(ambient);

  let directionalLight = new THREE.DirectionalLight("#ffffff", 0.5);
  directionalLight.position.x = -1000;
  directionalLight.position.y = 1000;
  directionalLight.position.z = 1000;
  scene.add(directionalLight);
  let light = new THREE.SpotLight(0xefdfd5, 1.3);
  light.position.y = 100;
  light.target.position.set(0, 0, 0);
  light.castShadow = true;
  light.shadow.camera.near = 50;
  light.shadow.camera.far = 110;
  light.shadow.mapSize.width = 1024;
  light.shadow.mapSize.height = 1024;
  scene.add(light);

  // FLOOR
  var floorMaterial = new THREE.MeshPhongMaterial({
    color: "#eeeeee",
    side: THREE.DoubleSide
  });
  var floorGeometry = new THREE.PlaneGeometry(50, 50, 10, 10);
  var floor = new THREE.Mesh(floorGeometry, floorMaterial);
  floor.receiveShadow = true;
  floor.rotation.x = Math.PI / 2;
  scene.add(floor);
  // SKYBOX/FOG
  var skyBoxGeometry = new THREE.CubeGeometry(10000, 10000, 10000);
  var skyBoxMaterial = new THREE.MeshPhongMaterial({
    color: 0x3b89b5,
    side: THREE.BackSide
  });
  var skyBox = new THREE.Mesh(skyBoxGeometry, skyBoxMaterial);
  scene.add(skyBox);
  scene.fog = new THREE.FogExp2(0x3b89b5, 0.00025);

  ////////////
  // CUSTOM //
  ////////////
  world = new CANNON.World();

  world.gravity.set(0, -9.82 * 20, 0);
  world.broadphase = new CANNON.NaiveBroadphase();
  world.solver.iterations = 16;

  DiceManager.setWorld(world);

  //Floor
  let floorBody = new CANNON.Body({
    mass: 0,
    shape: new CANNON.Plane(),
    material: DiceManager.floorBodyMaterial
  });
  floorBody.quaternion.setFromAxisAngle(
    new CANNON.Vec3(1, 0, 0),
    -Math.PI / 2
  );
  world.add(floorBody);

  //Walls
  setDie(diceNum);
  scene.add(die.getObject());
  dice.push(die);

  function randomDiceThrow() {
    var diceValues = [];

    let yRand = Math.random() * 20;
    die.getObject().position.x = -15;
    die.getObject().position.y = 2;
    die.getObject().position.z = -15;
    die.getObject().quaternion.x =
      ((Math.random() * 90 - 45) * Math.PI) / 180;
    die.getObject().quaternion.z =
      ((Math.random() * 90 - 45) * Math.PI) / 180;
    die.updateBodyFromMesh();
    let rand = Math.random() * 5;
    die
      .getObject()
      .body.velocity.set(25 + rand, 40 + yRand, 15 + rand);
    die
      .getObject()
      .body.angularVelocity.set(
      20 * Math.random() - 10,
      20 * Math.random() - 10,
      20 * Math.random() - 10);
    diceNum = diceNum == 'Да/нет' ? 2 : diceNum
    diceValues.push({dice: die, value: Math.ceil(diceNum * Math.random())});
    console.log(`val: ${diceValues[0].value}`);
    DiceManager.prepareValues(diceValues);
  }

  document
    .querySelector("#ThreeJS")
    .addEventListener("click", randomDiceThrow);
  // setInterval(randomDiceThrow, 3000);
  // randomDiceThrow();

  requestAnimationFrame(animate);
}

function animate() {
  updatePhysics();
  render();
  update();

  requestAnimationFrame(animate);
}

function updatePhysics() {
  world.step(1.0 / 60.0);

  for (var i in dice) {
    die.updateMeshFromBody();
  }
}

function update() {
  controls.update();
  stats.update();
}

function render() {
  renderer.render(scene, camera);
}
