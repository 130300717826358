<template>
  <div class="home-page">
    <div class="home-page-title title">Психологические игры и метафорические карты <br>Анны Суднеко</div>
  </div>
</template>

<script>
import Item from "@/components/Item";
import Piece from "@/components/Piece";
import {mdiPlus, mdiMinus} from "@mdi/js"

export default {
  name: "Home",
  components: {Item, Piece},
  data() {
    return {
      mdiPlus, mdiMinus,
      cardsOnField: [],
      game_field: '',
    }
  },
  methods: {},
  mounted() {
    console.log(this.$store.state.user)
  },
  created() {

  }
}
</script>

<style scoped lang="scss">
.home-page {
  order: 1;
  &-title {
  }

}
</style>
