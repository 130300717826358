import {DiceD4, DiceD6, DiceD8} from "threejs-dice";
import * as THREE from "three";
export class DiceD2 extends DiceD6 {
  constructor(options) {
    super(options);
    this.faceTexts = [' ', '0', '+', '-', '+', '-', '-', '+', '7', '8',
      '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
    this.create();
  }
}


export class DiceD4_ extends DiceD8 {
  constructor(options) {
    super(options);
    this.faceTexts = [' ', '0', '1', '4', '2', '1', '3', '2', '3', '4',
      '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20'];
    this.create();
  }
}
