<template>
  <page-container class="game-page-container">
    <h1 v-if="course.id">Видеокурс «{{ course.title }}»</h1>
    <div class="about" v-if="course.id">
      <div style="width: 100%">
        <p v-html="course.about?course.about.split('\n').join('<br>'):''"></p>
        <template v-if="!course.hasAccess && +course.price">
          <Button @click="$router.push('/cart')" style="margin-top: 20px"
                  v-if="$store.state.user.cart.items.find(item=>(item.type==='course' && item.item_id==course.id))"
                  text="Перейти в корзину" color="white"
                  text_color="var(--primary-text-color)"/>
          <Button v-else style="margin-top: 20px;font-size: 16px;"
                  @click="$eventBus.emit('add-to-cart', {name: course.title, type:'course',item_id:course.id})"
                  :text="`Купить за ${course.price} ₽ на 1 год`"/>
        </template>
        <p style="margin-top: 20px" v-else-if="course.hasAccess">Доступ к курсу оплачен до
          {{ course.hasAccess.date_to ? course.hasAccess.date_to.split('-').reverse().join('.') : ' "Бессрочно"' }}</p>
        <div style="margin-top: 20px;margin-bottom: 10px;font-size: 18px">Видеоуроки:</div>
        <div style="display: flex;flex-direction: column;gap: 20px;">
          <div v-for="(video, index) in course.videos" :key="video.id">
            <video v-if="selectedVideo===index" :src="video.link" controls
                   style="width: 100%;border-radius: 8px"></video>
            <div @click="course.hasAccess && (selectedVideo = index)"
                 style="background: white;padding: 10px 20px;width: calc(100% - 40px);border-radius: 8px;cursor: pointer"
                 :style="selectedVideo===index?`background: var(--primary-text-color);color:white`:''">
              {{ index + 1 }}. {{ video.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="right-block">
        <img class="image" :src="course.preview" :alt="course.title"/>
      </div>
    </div>
  </page-container>
</template>

<script>
export default {
  name: "GameInfo",
  data() {
    return {
      course: {videos: []},
      selectedVideo: null,
    }
  },
  methods: {
    load() {
      this.$server.request(`course/get/${this.$route.params.id}`, {}, (data) => {
        this.course = data.response
      })
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style scoped lang="scss">
.game-page-container {
  .about {
    display: flex;
    gap: 40px;
    justify-content: space-between;

    .right-block {
      float: right;
      width: 100%;
      max-width: 400px;
    }

    .image {
      min-width: 250px;
      border-radius: 10px;
      height: auto;
    }

    .button {
      //margin-top: 10px;
    }
  }
}

.page-container {
  max-width: calc(100% - 40px);
  width: 900px;
  margin: 20px auto;
}

.tariffs {
  display: flex;
  gap: 20px;
  padding: 20px;
  padding-left: 10px;
}

.access {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  align-items: center;
}

.sessions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px
}

@media (max-width: 900px) {
  .game-page-container .about {
    flex-direction: column-reverse;
  }
  .page-container {
    width: calc(100% - 40px);
  }
  .tariffs {
    flex-direction: column;
  }
  .access, .sessions {
    gap: 20px;
    flex-direction: column;
  }
  .button {
    width: calc(100% - 20px);
  }
}
</style>
