<template>
  <div v-if="guestToken" class="game-container">
    <div @click.stop="bigCard = ''" v-if="bigCard" class="fullscreen_block">
      <img style="width:auto;height:calc(100vh - 20px);margin:auto;display:block;margin-top: 10px" :src="bigCard"/>
    </div>
    <div v-if="viewDeck" class="fullscreen_block view-deck">
      <svg-icon
          class="close-modal"
          type="mdi"
          :path="mdiClose"
          size="32"
          style="top: 12px;right: 20px;position: fixed;color: white;cursor: pointer"
          @click="viewDeck = null"
      />
      <img v-for="card in selectedDeck.cards" @click="viewDeck = null;moveToField(card)" :key="card"
           :src="url('game2/img', {deck:selectedDeck.id, card})"/>
    </div>
    <div class="field-container">
      <img :src="game.field+`&guest_token=${guestToken}&game_token=${token}&token=${$server.token}`"
           style="max-width: 100%;margin:auto;max-height: 100%;height:auto;" alt="" ref="field" class="game-field">
      <Item size="big"
            type="card"
            @change="updatePosition('card', prepareCardID(card), $event)"
            v-for="(card, i) in cardsOnField"
            :movingClass="!!cardsOnField[i].moving"
            :key="prepareCardID(card)"
            :initial-width="card.deck.width"
            :initial-position="[$refs.items_container.offsetLeft-80, ($refs.items_container.offsetTop+i*170)%(screenHeight-400)+20]"
            :item="prepareCardID(card)"
            :value="card.position"
            :back="card.deck.back"
            :front="url('game2/img', {deck:card.deck.id,card: card.card})"
            :is-draggable="true"
            :remove-from-field-icon="true"
            :is-flipped="true"
      />
    </div>
    <div v-if="rightMenuClosed" @click="rightMenuClosed = false" style="transform: rotate(180deg);
    position: fixed;
    right: 0;
    background: #fff;
    padding: 10px;
    border-radius: 0 5px 0 0;">
      &#10095;
    </div>
    <div :class="{rightMenuClosed}" class="items-container" :style="game.bg_colors.length>1 ? 'flex-basis: 15%;' : ''"
         ref="items_container"
         style="box-shadow: 0 0 10px black;">
      <div class="item-first" :class="{width50:game.bg_colors.length<=1}"></div>
      <div class="item-second" :class="{width50:game.bg_colors.length<=1}">
        <div class="game-actions">
          <a class="button" href="/cubes" target="_blank">Кубики</a>
          <a v-if="game.instructions.length" @click="showInstructions" class="button" target="_blank">Инструкция</a>
          <viewer v-if="game.instructions.length" :images="game.instructions"></viewer>
        </div>
        <div class="piece-select items-select" v-if="+game.cards_count">
          <div class="items">
            <img @click="bigCard = url('game2/bigCard', {number: i})"
                 v-for="i in +game.cards_count" :key="i"
                 :src="url('game2/bigCard', {back: 1,number: i})"
                 class="big_card">
          </div>
        </div>
        <div class="piece-select items-select" v-if="game.fields_count>1 || game.bg_colors.length>=1">
          <div class="select-title">Поля</div>
          <div class="items" v-if="game.bg_colors.length<=1">
            <div class="big_card" @click="setField(game.fields_count ? game.fields_count.length:1)"
                 style="background: #3b89b5;height: auto;width: 100%;"></div>
            <img @click="setField(i)" v-for="i in game.fields_count" :key="i"
                 :src="game.field+`&guest_token=${guestToken}&number=${i-1}&game_token=${token}`"
                 class="big_card">
          </div>
          <template v-else>
            <div @click="setColor(c)" v-for="c in game.bg_colors" :style="'background:'+c" :key="c"
                 style="width: 30px;height: 30px;border: 1px solid white;display: inline-block;cursor: pointer"></div>
          </template>
        </div>
        <div class="piece-select items-select">
          <div class="select-title" style="cursor: pointer" @click="showPieces=!showPieces">Фишки</div>
          <div class="items" :style="showPieces?'':'height: 0;overflow:hidden'">
            <Piece
                v-for="i in 8"
                :key="i"
                :id="i"
                :value="pieces[i]"
                @change="updatePosition('piece', i, $event)"
            />
          </div>
        </div>
        <div class="decks-select items-select"
             v-if="current_selection === 'decks'"
        >
          <div class="select-title">Колоды</div>
          <div class="decks">
            <ItemSelect
                v-for="(deck, i) in game.decks.filter(d=>d.hasAccess)"
                :key="i"
                type="deck"
                :preview="deck.back"
                :label="deck.title"
                @click="setDeck(deck)"
            />
          </div>
        </div>
        <div v-if="current_selection === 'cards'" class="cards-select items-select">
          <div class="select-title">
            <div>
            <svg-icon size="18"
                      type="mdi"
                      :path="mdiArrowLeft"
                      @click="current_selection = 'decks'"
                      class="icon-btn"
            />
            </div>
            <div style="text-align: center;width: 100%;padding-right: 10px;white-space: nowrap">{{ selectedDeck.title }}</div>
          </div>
          <div class="plain_button" @click="isFlipped = !isFlipped">Перевернуть карты</div>
          <div class="plain_button" @click="selectRandomCard">Вытащить случайную</div>
          <div class="plain_button" @click="shuffle">Перемешать колоду</div>
          <div class="plain_button" v-if="+$store.state.user.admin || ![1,11,22].includes(+selectedDeck.id)"
               @click="viewDeck = selectedDeck">Посмотреть колоду
          </div>
          <div class="cards">
            <Item size="small"
                  type="card"
                  v-for="card in selectedDeck.cards" :key="card"
                  :item="card"
                  :back="selectedDeck.back"
                  :front="url('game2/img', {deck:selectedDeck.id, card})"
                  :is-flipped="isFlipped"
                  :is-hidden="!!cardsOnField.find(c=>prepareCardID(c)===prepareCardID({card: card, deck:{id:selectedDeck.id}}))"
                  @click="moveToField(card)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>Присоединение к игре...</div>
</template>

<script>
import ItemSelect from "@/components/ItemSelect";
import Piece from "@/components/Piece";
import {mdiArrowLeft, mdiClose} from '@mdi/js'

export default {
  name: "Game",
  components: {
    ItemSelect,
    Piece
  },
  data() {
    let pieces = [];
    for (let i = 0; i < 8; ++i) pieces[i] = {};
    return {
      token: this.$route.params.token,
      current_selection: 'decks',
      selectedDeck: {},
      cardsOnField: [],
      pieces,
      mdiClose,
      onResizeDebounced: null,
      bigCard: '',
      viewDeck: null,
      rightMenuClosed: false,
      guestToken: localStorage.getItem('guesttoken_' + this.$route.params.token),
      screenHeight: window.screen.availHeight,
      showPieces: false,
      mdiArrowLeft,
      last_update: 0,
      timeout: 300,
      fieldPosition: null,
      game: {bg_colors: [], decks: [], screenshots: [], hasAccess: {}, instructions: []},
      updatingPosition: true,
      isFlipped: false
    }
  },
  methods: {
    request(method, data = {}, onSuccess, onError) {
      if (this.guestToken) data.guest_token = this.guestToken;
      data.game_token = this.token;
      this.$server.request(method, data, onSuccess, onError);
    },
    setField(i, update = true) {
      this.game.field = this.game.fieldInitial + `&number=${i - 1}`
      if (update) this.updatePosition('field', 1, {number: i});
    },
    updatePosition(type, id, data) {
      if (this.updatingPosition) return;
      this.updatingPosition = true;
      // console.log({type, id, data, event});
      if (!data || type === 'card' && !data.x && !data.remove) return;
      data.f = this.fieldPosition;
      this.request('game2/updatePosition', {id, type, data: JSON.stringify(data)},
          () => this.updatingPosition = false, () => this.updatingPosition = false
      );
    },
    showInstructions() {
      this.$viewerApi({
        images: this.game.instructions.map(url => url + `&token=${this.$server.token}`),
      })
    },
    url(method, params) {
      let query = '';
      params.guest_token = this.guestToken;
      params.game_token = this.token;
      params.token = this.$server.token;
      for (let i in params) query += `${i}=${params[i]}&`;
      return this.$server.url + `${method}/${query ? '?' + query : ''}`;
    },
    setDeck(deck) {
      this.selectedDeck = deck
      this.current_selection = 'cards';
      this.selectedDeck.cards = [];
      for (let i = 1; i <= +deck.number; ++i) this.selectedDeck.cards.push(i);
    },
    shuffle() {
      this.selectedDeck.cards = this.$store.state.shuffle(this.selectedDeck.cards);
    },
    prepareCardID(card) {
      return card.deck.id + '_' + card.card;
    },
    onResize() {
      this.setFieldPosition();
      if (this.onResizeDebounced) this.onResizeDebounced();
    },
    getPosition() {
      this.request(
          `game2/getPosition`,
          {last_update: this.last_update},
          (data) => {
            this.last_update = data.response.time;
            if (data.timeout && this.timeout !== data.timeout) this.timeout = data.timeout;
            let pushedOnField = false;
            this.setFieldPosition();
            data.response.rows.forEach(row => {
              if (row.data && row.data.z > window.maxZIndex) window.maxZIndex = row.data.z;
              this.prepareData(row.data, row.type);
              if (row.type === 'card') {
                let found = false
                this.cardsOnField.forEach((card, i) => {
                  if (this.prepareCardID(card) === row.item_id) {
                    if (row.data.remove) this.cardsOnField.splice(i, 1);
                    this.cardsOnField[i].position = row.data;
                    this.cardsOnField[i].moving = true;
                    if (this.cardsOnField[i].movingTimeout) clearTimeout(this.cardsOnField[i].movingTimeout);
                    this.cardsOnField[i].movingTimeout = setTimeout(() => {
                      this.cardsOnField[i].moving = false;
                      this.cardsOnField[i].movingTimeout = 0;
                    }, Math.max(this.timeout * 2, 200));
                    found = true;
                    return false;
                  }
                });
                let [deck_id, card_id] = row.item_id.split('_');
                if (!found) {
                  pushedOnField = true;
                  let card = {
                    card: card_id,
                    deck: this.game.decks.find(d => d.id === deck_id),
                    position: {...row.data}
                  };
                  this.cardsOnField.push(card)
                }
              } else if (row.type === 'piece') {
                // row.data.y =
                this.pieces[+row.item_id] = row.data;
              } else if (row.type === 'field') {
                this.setField(row.data.number, false);
              } else if (row.type === 'fieldColor') {
                this.setColor(row.data.color, false);
              }
            });
            // if (pushedOnField) this.$forceUpdate();
            setTimeout(() => this.updatingPosition = false, 500);
            if (this.game.hasAccess.online) setTimeout(this.getPosition, this.timeout);
          },
          () => {
            setTimeout(this.getPosition, this.timeout);
          }
      )
    },
    // preparePeiceData(data) {
    //   if (!data || !data.f) return;
    //   let coef = this.fieldPosition.h / data.f.h;
    //   if (data.y) data.y = (data.y - data.f.y) * coef + this.fieldPosition.y;
    // },
    prepareData(data, type) {
      if (!data || !data.f) return;

      let coef = this.fieldPosition.h / data.f.h;
      if (data.x) data.x = (data.x - data.f.x) * coef + this.fieldPosition.x;
      if (data.y) data.y = (data.y - data.f.y) * coef + this.fieldPosition.y;
      if (data.width) data.width = data.width * coef;
      if (data.height) data.height = data.height * coef;
      if (type === 'piece') {
        data.y -= 48 * (1 - coef);
        data.x -= 20 * (1 - coef);
      }
      // console.log('after prepare', JSON.parse(JSON.stringify([data, this.fieldPosition])));
    },
    loadGame() {
      this.request(`game2/get`, {}, (data) => {
        this.game = data.response
        this.game.fieldInitial = this.game.field;
        this.setFieldPosition(this.getPosition);
      }, (data) => {
        this.$eventBus.emit('show-error', data.error || 'Проверьте подключение к интернету');
      })
    },
    setFieldPosition(callback) {
      if (!this.$refs.field) return setTimeout(() => this.setFieldPosition(callback), 100);
      this.fieldPosition = {
        w: this.$refs.field.offsetWidth,
        h: this.$refs.field.offsetHeight,
        x: this.$refs.field.offsetLeft,
        y: this.$refs.field.offsetTop,
      }
      if ((!this.fieldPosition.h || this.fieldPosition.h < 50) && this.game.bg_colors.length <= 1) setTimeout(() => this.setFieldPosition(callback), 100);
      else if (callback && typeof callback === 'function') callback();
    },
    checkUser(callback) {
      if (!this.guestToken) this.request(`game2/createUser`, {}, (data) => {
        localStorage.setItem('guesttoken_' + this.token, data.response);
        this.guestToken = data.response;
        callback();
      }, data => this.$eventBus.emit('show-error', data.error || 'Не получилось присоединиться к игре'));
      else callback();
    },
    setColor(color, update = true) {
      document.getElementsByClassName('content-container')[0].style.backgroundColor = color;
      if (update) this.updatePosition('fieldColor', 1, {color});
    },
    moveToField(card) {
      this.$eventBus.emit('move-card-to-field', card)
    },
    selectRandomCard() {
      let randomCardIndex = this.getRandomIntInclusive(1, this.selectedDeck.number)
      this.moveToField(randomCardIndex)
    },
    getRandomIntInclusive(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    contextMenuCallback(event) {
      event.preventDefault()
    },
  },
  computed: {
    // showRightField() {
    //   return this.game.bg_colors.length>1 && this.$vuetify.breakpoint.width>768;
    // }
  },
  mounted() {
    document.addEventListener('contextmenu', this.contextMenuCallback);
    const debounce = (func, delay) => {
      let debounceTimer;
      return function () {
        const context = this;
        const args = arguments;
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(() => func.apply(context, args), delay);
      }
    }
    this.onResizeDebounced = debounce(() => location.reload(), 500);
    document.getElementById('app').classList.add('in_game');
    window.maxZIndex = 100;
    window.addEventListener('resize', this.onResize);
    this.checkUser(this.loadGame);
    this.$eventBus.on('move-card-to-field', (card) => {
      this.cardsOnField.push({
        card,
        deck: {
          ...this.selectedDeck,
        },
        position: {
          x: this.$refs.items_container.offsetLeft - 80,
          y: (this.$refs.items_container.offsetTop + this.cardsOnField.length * 170) % (this.screenHeight - 400) + 20,
          r: 0,
          z: ++window.maxZIndex,
        }
      })
    })
    this.$eventBus.on('remove-card-from-field', (card) => {
      let index = this.cardsOnField.findIndex(c => this.prepareCardID(c) === card)
      if (index === -1) return;
      let cardObj = {...this.cardsOnField[index]};
      // console.log({index, card, t: {...this.cardsOnField}})
      this.cardsOnField.splice(index, 1);
      this.updatePosition('card', this.prepareCardID(cardObj), {remove: 1});
    })
  },
  beforeUnmount() {
    document.removeEventListener('contextmenu', this.contextMenuCallback);
    window.removeEventListener('resize', this.onResize);
    this.$eventBus.off('move-card-to-field');
    document.getElementById('app').classList.remove('in_game');
    if (this.game.bg_colors.length > 1) document.getElementsByClassName('content-container')[0].style.backgroundColor = this.game.bg_colors[0];
  }
}
</script>
<style>
/*.content-container {*/
/*  background-image: none !important;*/
/*}*/
</style>
<style scoped lang="scss">

.fullscreen_block {
  position: fixed;
  background: #0000006b;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1000000
}

.view-deck {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  padding-top: 60px;
  overflow-y: auto;
  padding-bottom: 20px;
  height: calc(100% - 80px);
  background: #000000b8;

  img {
    width: calc(25% - 10px);
    height: auto;
    min-width: 250px;
    border-radius: 5px;
    cursor: pointer;
  }
}

.game-container {
  display: flex;
  width: 100%;
  height: 100%;
  order: 2;
  position: relative;

  .rightMenuClosed {
    position: absolute;
    left: 100000px;
  }

  .game-actions {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;

    .button {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .field-container {
    flex-basis: 100%;
    height: 100%;
    //margin-right: 16px;
    //border-radius: 8px;
    //overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;

    .game-field {
      width: auto;
    }
  }

  .items-container {
    background: #b3d0e2;
    //border-radius: 8px;
    flex-basis: 30%;
    padding: 16px;
    overflow-y: scroll;

    .width50 {
      width: 50%;
    }

    .item-first {
      display: inline-block;
    }

    .item-second {
      display: inline-block;
      padding-bottom: 40px
    }

    @media (max-width: 768px) {
      .item-first {
        display: none;
      }

      .item-second {
        width: 100%;
        padding-bottom: 60px;
      }
    }

    .items-select {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      .select-title {
        margin-bottom: 5px;
        display: flex;
        user-select: none;
        overflow: hidden;
      }

      .cards, .decks {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }

      &.piece-select .items {
        position: initial;
      }

      .items {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        gap: 10px;

        .item-select {
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .big_card {
    cursor: pointer;
    border-radius: 8px;
    max-width: calc(50% - 10px);
  }
}
</style>
