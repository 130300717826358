<template>
  <page-container class="education-page-container">
    <div class="title">Обучение</div>
    Мой курс по работе с метафорическими картами в данный момент состоит из 2х частей.<br>
    Обучение индивидуальное.<br>
    <br>
    БАЗОВЫЙ КУРС<br>
    1. Метафорические карты. Возможности и ресурсы.<br>
    <br><br>
    2 онлайн - встречи. Стоимость12.000<br>
    Основные вопросы, которые мы затронем:<br>
    История возникновения метафорических карт.<br>
    Психологические механизмы работы - как и почему работают метафорические карты.<br>
    Обзор колод, их специфика, нюансы, основные аспекты работы с ними.<br>
    Правила работы с метафорическими картами.<br>
    Работа в открытую и вслепую.<br>
    Алгоритмы работы с метафорическими картами.<br>
    Интеграция различных методов при работе с метафорическими картами.<br>
    Работа с запросом, с эмоциональной сферой, с темами целей и отношений.<br>
    Применение карт в трансформационных играх.<br>
    <br>
    ПРОДВИНУТЫЙ КУРС<br>
    2. - Более углубленное изучение работы с картами.<br>
    <br>
    Метафорические карты. Эмоциональное исцеление и трансформации.<br>
    <br>
    Также 2 встречи - 12.000<br>
    <br>
    Более глубокая работа с картами.<br>
    Мы затронем такие вопросы как:<br>
    Работа с психологической травмой.<br>
    Работа с кризисами и поиском смыслов<br>
    Психосоматика<br>
    Техники работы с субличностями, травматическим расщеплением.<br>
    Работа с родом.<br>
    Диагностика и трансформация при помощи метафорических карт.<br>
    Телесные техники<br>
    Структурные и системные расстановки при помощи метафорических карт (обзор)<br>
    Особенности работы онлайн<br>
    Креативность и индивидуальный стиль работы<br>
    Супервизия, профессиональное выгорание и личная терапия у специалистов по работе с метафорическими картами.<br>
    <br>
    Стоимость за полный курс  20000 - базовый плюс углубленный при единовременной оплате и полном прохождении в течении 2 недель.
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
export default {
  name: "Education",
  components: {PageContainer}
}
</script>

<style scoped>

</style>
