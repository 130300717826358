<template>
  <page-container class="education-page-container">
    <div class=WordSection1>

      <p class=MsoNormal align=center style='margin-top:4.8pt;margin-right:0in;
margin-bottom:3.35pt;margin-left:0in;text-align:center;line-height:normal'><b><span
          lang=RU style='font-size:14.0pt;font-family:"Calibri Light",sans-serif;
color:black'>Пользовательское соглашение</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>Настоящее
Соглашение является публичной офертой и&nbsp;определяет условия использования
материалов и&nbsp;сервисов, размещенных на&nbsp;сайте Исполнителя в&nbsp;сети
Интернет посетителями и&nbsp;пользователями данного интернет-сайта.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>1. Термины,
используемые в&nbsp;настоящем Соглашении</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>1.1.
Покупатель (Пользователь)&nbsp;— физическое лицо,
достигшее&nbsp;18-летнего&nbsp;возраста, обладающее необходимой право- и
дееспособностью в соответствии с законодательством РФ, пользователь сети
Интернет, принявший условия настоящего Соглашения и&nbsp;оформивший Заказ на&nbsp;сайте
Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>1.2. Администрация
Сайта (Исполнитель)&nbsp;— </span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>ИП Суднеко А.М</span><span
          lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>1.4. Услуга
предоставления доступа к информационно-консультационному ресурсу - доступа к
сопутствующим цифровым информационным материалам (изображениям, видеозаписям,
аудиозаписям, текстовым файлам и д.р.).</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>1.5. Заказ&nbsp;—
заявка Пользователя на&nbsp;приобретение услуги из&nbsp;каталога Программ
цифровых материалов, оформленная посредством электронной формы на&nbsp;Сайте
(далее «Корзина») самим Пользователем. <a>Подтверждением факта оформления
Заказа является его покупка.</a></span><span class=MsoCommentReference><span
          lang=RU style='font-size:8.0pt'><a class=msocomanchor id="_anchor_1"
                                             onmouseover="msoCommentShow('_anchor_1','_com_1')"
                                             onmouseout="msoCommentHide('_com_1')" href="#_msocom_1" language=JavaScript
                                             name="_msoanchor_1">[U1]</a>&nbsp;</span></span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>При оформлении
Заказа Покупатель указывает следующую информацию:</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;Фамилия,
имя Покупателя;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;Контактный
телефон оператора мобильной связи, оказывающего услуги на&nbsp;территории РФ;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;Электронную
почту.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>1.6. Сайт Исполнителя
(Сайт)&nbsp;— информационный ресурс в&nbsp;сети Интернет, принадлежащий Исполнителю,
расположенный на&nbsp;одном из&nbsp;следующих доменов: </span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=RU
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>,
</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>2. Предмет
Соглашения</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>2.1.
Продавец предоставляет возможность Покупателю на&nbsp;платной основе
приобретать доступ к информационно-консультационному ресурсу -предоставления
доступа к сопутствующим цифровым информационным материалам (изображениям, видеозаписям,
аудиозаписям, текстовым файлам и д.р.).</span></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>&nbsp;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>2.2. Нажатием
соответствующей кнопки, Пользователь подтверждает:</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;факт
достижения им&nbsp;18-летнего&nbsp;возраста;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;согласие
с&nbsp;условиями настоящего Соглашения;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;согласие
с&nbsp;условиями Политики конфиденциальности Сайта.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>3. Обязанности
сторон</span></b></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>3.1. Обязанности Исполнителя:</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>3.1.1.
Предоставлять Покупателю ссылки для доступа не&nbsp;позднее 24&nbsp;часов
с&nbsp;момента подтверждения произведенной оплаты и&nbsp;идентификации
Покупателя как плательщика произведенного платежа.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.1.2. Не&nbsp;разглашать
третьим лицам адрес электронной почты Покупателя, а&nbsp;также прочие сведения,
полученные от &nbsp;Покупателя при регистрации и&nbsp;формировании Заявки, если
иное не&nbsp;требуется в&nbsp;случаях, предусмотренных законодательством РФ, а
также в целях исполнения договорных обязательств перед Покупателем.</span></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>3.2. Обязанности
Покупателя:</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.1.
При регистрации и/или оформлении Заказа указать всю необходимую информацию,
указанную в&nbsp;пункте 1.5. Соглашения.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.2. При
регистрации и/или оформлении Заказа следовать указаниям Исполнителя
о&nbsp;порядке оформления заказа, размещенном на&nbsp;Сайте Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.3. Производить
оплату за&nbsp;услуги согласно пункту 4&nbsp;Соглашения.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.4.
Использовать доступ к информационно -консультационному ресурсу исключительно
в&nbsp;целях, предусмотренных Программой цифрового материала</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.5. Покупатель
соглашается не&nbsp;предпринимать действий, которые могут рассматриваться как
нарушающие российское законодательство или нормы международного права,
в&nbsp;том числе в&nbsp;сфере интеллектуальной собственности, авторских и/или
смежных прав, общепринятые нормы морали и&nbsp;нравственности, а&nbsp;также
любых действий, которые приводят или могут привести к&nbsp;нарушению нормальной
работы Сайта и&nbsp;сервисов Сайта. </span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.6. Удалить
аккаунт на&nbsp;Сайте и&nbsp;в&nbsp;Приложениях Исполнителя и&nbsp;связанные
с&nbsp;ним персональные данные без неоправданной задержки. Чтобы
воспользоваться данным правом, необходимо обратиться c&nbsp;соответствующим
запросом по&nbsp;электронной почте&nbsp;</span><span lang=EN-GB
                                                     style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>3.2.7. Покупатель
не&nbsp;имеет права:</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;скачивать,
передавать либо рассылать размещенные на информационно -консультационном
ресурсе материалы третьим лицам как полностью, так и&nbsp;частично;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;использовать
приобретенный доступ к услугам для последующей и&nbsp;заведомой для Покупателя
продажи третьим лицам с&nbsp;целью извлечения прибыли;</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;транслировать
и размещать, содержащиеся на информационно -консультационном ресурсе материалы
по&nbsp;известным каналам вещания, таким как радио, телевидение, интернет
и&nbsp;др.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;доводить размещенные
на информационно -консультационном ресурсе материалы до&nbsp;всеобщего сведения
с&nbsp;использованием сети Интернет и&nbsp;прочих цифровых сетей, кроме
случаев, предусмотренных программой цифрового материала.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;изменять
или прочим образом перерабатывать содержание программ, размещенных на
информационно -консультационном ресурсе.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>Покупатель
предупрежден о&nbsp;том, что нарушение п.3.2.7. Соглашения является нарушением
законодательства об&nbsp;интеллектуальной собственности и&nbsp;влечет наложение
ответственности, предусмотренной действующим законодательством&nbsp;РФ,
а&nbsp;также нормами международного права.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.8.
Использование материалов Сайта, без согласия правообладателей
не&nbsp;допускается. Вся информация и изображения, размещаемые на&nbsp;Сайте,
являются собственностью Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>3.2.9. При использовании
материалов Сайта, включая охраняемые авторские произведения, ссылка
на&nbsp;Сайт и правообладателя обязательна. Допускается использование
материалов исключительно в целях и способами, предусмотренными настоящим
Договором и Программой электронных материалов сайта.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>4. Порядок
приобретения Товара</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>4.1.
Для совершения покупки у&nbsp;Исполнителя требуется оформить Заказ
на&nbsp;сайте Исполнителя либо пройти регистрацию на&nbsp;Сайте
в&nbsp;установленном порядке и&nbsp;оформить Заказ на&nbsp;сайте Исполнителя.
Регистрация на&nbsp;Сайте осуществляется с&nbsp;использованием личной
электронной почты, необходимой для авторизации.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.2. В&nbsp;случае
изменения каких-либо данных, ранее предоставленных Покупателем
и&nbsp;необходимых для надлежащего исполнения Исполнителем обязательств,
Покупатель обязуется незамедлительно уведомить об&nbsp;этом Исполнителя,
направив соответствующее письмо на электронный адрес </span><span lang=EN-GB
                                                                  style='font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>@</span><span
          lang=EN-GB style='font-family:"Calibri Light",sans-serif;color:#333333'>bk</span><span
          lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          lang=EN-GB style='font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
          lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.3. Исполнитель
не&nbsp;несет ответственность за&nbsp;неисполнение и/или ненадлежащее
исполнение обязательств по оказанию услуг, если указанное нарушение связано
с&nbsp;предоставлением недостоверных и/или недействительных данных о&nbsp;Покупателе,
а&nbsp;равно с&nbsp;невыполнением Покупателем условий настоящего Соглашения.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.4. Исполнитель
прилагает достаточные усилия, чтобы состав материалов, характеристики
соответствовали описаниям, приведенным в&nbsp;каталоге Программ цифровых
материалов&nbsp;на&nbsp;Сайте.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.5. Цена за
услуги изменяется на&nbsp;Сайте на усмотрение Исполнителя. Информация
о&nbsp;цене актуальна на&nbsp;момент оформления Заказа.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>Исполнитель
не&nbsp;компенсирует Покупателю стоимость услуги доступа в&nbsp;случае, если
со&nbsp;временем Программа цифрового материала становится акционной и&nbsp;ее
стоимость снижается. Покупатель принимает и&nbsp;соглашается, что
по&nbsp;истечении определенного периода цена акционной программы может
изменяться, а&nbsp;бесплатный акционная программа может стать платной&nbsp;наоборот.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.7. Заказ может
быть аннулирован Исполнителем в&nbsp;одностороннем порядке, если иное
не&nbsp;было согласовано со&nbsp;службой поддержки при формировании заказа
в&nbsp;отсутствие в&nbsp;течение 7&nbsp;календарных дней с&nbsp;даты оформления
Заказа:</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;связи
с&nbsp;Покупателем.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.8. Сайт принимает
оплату за&nbsp;Товар в&nbsp;российских рублях согласно действующему
законодательству Российской Федерации.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.9. Все вопросы,
связанные с&nbsp;приобретением доступа из&nbsp;каталога на&nbsp;Сайте,
Вы&nbsp;можете направить в&nbsp;Службу клиентской поддержки
по&nbsp;адресу&nbsp;</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span lang=RU
                                                                  style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>com</span><span lang=RU style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>, </span><span style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.12. Заказы,
письма, звонки и&nbsp;другие обращения, содержащие информацию оскорбляющую
честь, достоинство, деловую репутацию Исполнителя и&nbsp;его сотрудников,
а&nbsp;равно изложенные в&nbsp;некорректной форме, в&nbsp;том числе
с&nbsp;применением ненормативной лексики, обработке не&nbsp;подлежат.</span></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>4.13. Условия
предоставления скидок на&nbsp;приобретение услуг</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>4.13.1.
Промокод&nbsp;— индивидуальная скидка для пользователя, которую клиент может
получить от&nbsp;сотрудника компании или партнера, а&nbsp;также
на&nbsp;официальных площадках в&nbsp;соцсетях или рассылках.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.13.2. Исполнитель
вправе изменять условия предоставления скидок и&nbsp;пересматривать иные
условия поощрения в&nbsp;одностороннем порядке. В&nbsp;случае таких изменений
Покупатель не&nbsp;вправе требовать от&nbsp;Исполнителя оставлять прежние
условия скидок и&nbsp;поощрений, не&nbsp;вправе требовать выплат и&nbsp;возмещения
убытков.</span></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>4.14. Условия оплаты
Товара физическими лицами</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>4.14.1.
Физическое лицо может оплатить стоимость услуг на&nbsp;Сайте кредитной картой</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>Оплата Товара
может осуществляться с&nbsp;использованием следующих дебетовых и&nbsp;кредитных
карт: Visa, Visa Electron, MasterCard, Maestro, МИР на&nbsp;сайте.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.14.2. Покупатель
понимает и&nbsp;соглашается с&nbsp;тем, что оператором по&nbsp;приему
и&nbsp;проведению платежей за услуги, являются, в&nbsp;зависимости
от&nbsp;обстоятельств, одна из&nbsp;компаний-эквайеров, подключенная
к&nbsp;Сайту и&nbsp;выбранная самостоятельно Покупателем в&nbsp;процессе
осуществления оплаты заказа.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>Информация
о&nbsp;реквизитах банковской карты Покупателя и&nbsp;данных, необходимых для
авторизации платежной операции передается Покупателем посредством заполнения
специального поля при оплате заказа на&nbsp;сайте непосредственно
компании-эквайеру и&nbsp;подлежит обработке и&nbsp;защите
от&nbsp;несанкционированного доступа силами компании-эквайера.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.15.
В&nbsp;случае оплаты электронными деньгами и/или кредитной картой подтверждение
Заказа осуществляется после поступления денежных средств на&nbsp;расчетный счет
Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.16.
Предоставление доступа услугам Исполнителя осуществляется только после полной
оплаты стоимости услуг на&nbsp;сайте.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.17. После оплаты
услуг на&nbsp;сайте Покупатель получает на&nbsp;e-mail электронный кассовый
чек. </span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.18.
Ответственность за&nbsp;денежные переводы, осуществляемые Покупателем,
полностью возлагается на&nbsp;банковские учреждения и&nbsp;платежные системы,
услугами которых пользуется Покупатель. Продавец ответственности не&nbsp;несет.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.19. Продавец
не&nbsp;несет ответственность за&nbsp;работу интернет провайдеров,
процессинговых центров, платежных систем, операторов связи, банковских
учреждений, сервисов оплаты, в&nbsp;результате действия (бездействия) которых,
необходимая информация не&nbsp;поступила или поступила с&nbsp;опозданием, была
утеряна или повреждена. Любые претензии и&nbsp;споры по&nbsp;проведению
платежей и&nbsp;получению денежных возвратов Покупатель посылает такой
самостоятельно выбранной компании.</span></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>4.20. Условия оплаты
Товара юридическими лицами .</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>4.20.1.
Приобретение услуг юридическими лицами может осуществляться путем заключения
договора по&nbsp;инициативе лица, оформляющего заказ при предварительном
согласовании с Исполнителем. </span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.20.2. Оплата
за&nbsp;услуги осуществляется путем безналичного перечисления денежных средств
по&nbsp;банковским реквизитам Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.20.3. Все
вопросы, связанные с&nbsp;приобретением услуг юридическим лицом, вы&nbsp;можете
направить по&nbsp;электронному адресу&nbsp;</span><span lang=EN-GB
                                                        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>4.20.4. Настоящее
Соглашение не&nbsp;применяется к&nbsp;отношениям сторон по&nbsp;поставке
товаров юридическим лицам (ЮЛ) и&nbsp;индивидуальным предпринимателям (ИП)</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>5. О&nbsp;доставке
товара</span></b></p>

      <p class=MsoNormal style='margin-top:23.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>5.1. Общие положения</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>5.1.1.
Продавец осуществляет оказание услуг следующими способами:</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;предоставление
доступа к&nbsp;Информационно-консультационному ресурсу.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>5.1.2.
В&nbsp;случае отказа Покупателя от&nbsp;получения услуг до&nbsp;момента начала
оказания, уплаченная Покупателем стоимость подлежит возврату Покупателю
за&nbsp;вычетом расходов Исполнителя на организацию предоставления доступа. Указанная
сумма оплачивается Покупателю не&nbsp;позднее чем в&nbsp;течение
10&nbsp;календарных дней с&nbsp;даты предъявления Покупателем соответствующего
требования посредством заполнения формы заявления, направленной Исполнителем Покупателю
по&nbsp;запросу последнего с&nbsp;указанием паспортных данных и&nbsp;реквизитов
для перечисления денежных средств в&nbsp;Службу клиентской поддержки
по&nbsp;адресу&nbsp;</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span lang=RU
                                                                  style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>com</span><span lang=RU style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'> (</span><span style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>).</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>5.1.3. Все
вопросы, связанные со&nbsp;сроками и&nbsp;условиями оказания услуг, Покупатель
может направить в&nbsp;Службу клиентской поддержки Сайта по&nbsp;адресу&nbsp;</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>com</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>).</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>6. Ответственность
сторон. Ограничение ответственности Исполнителя</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>6.1.
Покупатель принимает на&nbsp;себя полную ответственность и&nbsp;риски,
связанные с&nbsp;оказанием услуг.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>6.2. Покупатель
полностью несет ответственность за&nbsp;использование третьими лицами
информации, передаваемой Исполнителем на&nbsp;адрес электронной почты, указанный
Покупателем при регистрации или при оформлении Заказа.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>6.3. Исполнитель
не&nbsp;несет ответственности за&nbsp;любые расходы Покупателя или прямой либо
косвенный ущерб, который может быть нанесен Покупателю вследствие использования
услуг не&nbsp;по&nbsp;прямому назначению.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>6.4. Исполнитель нe&nbsp;несёт
ответственности за&nbsp;качество доступа к&nbsp;услугам через сеть Интернет.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>6.5. Исполнитель
не&nbsp;несет ответственности за&nbsp;прямой или косвенный ущерб, понесенный
Покупателем в&nbsp;результате ошибок передачи данных, сбоев/дефектов
в&nbsp;работе программного обеспечения и/или оборудования, потерь
и&nbsp;повреждений данных, ошибок обработки или отображения данных, задержек
в&nbsp;передаче данных и&nbsp;других сбоев, случившихся не&nbsp;по&nbsp;вине Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>6.6. Сайт Исполнителя,
Мобильные приложения и&nbsp;все сопутствующие сервисы предоставляются
на&nbsp;условиях «как есть», без каких-либо прямых или косвенных гарантий того,
что указанные Сайт, Мобильные приложения&nbsp;и (или) сервисы могут подходить
или не&nbsp;подходить для конкретных целей использования.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>6.7. Исполнитель
не&nbsp;несет ответственности за&nbsp;невозможность пользования Сайтом,
Мобильными приложениям&nbsp;и (или) сопутствующими сервисами Пользователем
по&nbsp;каким-либо причинам, включая, но&nbsp;не&nbsp;ограничиваясь: ошибки,
упущения, прерывания, удаление, дефекты, задержку в&nbsp;обработке или передаче
данных, нарушение работы линий связи, неисправность оборудования, любые
технические сбои или иные проблемы любых телефонных сетей или служб,
компьютерных систем, серверов или провайдеров, компьютерного или телефонного
оборудования, программного обеспечения, невыполнение обязательств поставщиков
тех или иных услуг, кражу, уничтожение или неправомерный доступ к&nbsp;материалам
пользователей, размещенным на&nbsp;Cайте или в&nbsp;любом другом месте
и&nbsp;т.п.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>7. Срок действия
Соглашения</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>7.1.
Соглашение вступает в&nbsp;силу с&nbsp;момента оформления Заказа
и&nbsp;действует до&nbsp;полного исполнения Сторонами своих обязательств.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>8. Обстоятельства непреодолимой
силы</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>8.1.
Стороны освобождаются от&nbsp;ответственности за&nbsp;частичное или полное
неисполнение обязательств по&nbsp;настоящему Соглашению в&nbsp;случае, если
такое неисполнение явилось прямым следствием обстоятельств непреодолимой силы
(форс-мажорных обстоятельств), возникших после присоединения к&nbsp;Соглашению,
в&nbsp;результате событий чрезвычайного характера, а&nbsp;именно: пожара,
наводнения, урагана и&nbsp;землетрясения или наложения органами государственной
власти ограничений на&nbsp;деятельность любой из&nbsp;Сторон, и&nbsp;если эти
обстоятельства Стороны не&nbsp;могли ни&nbsp;предвидеть, ни&nbsp;предотвратить
разумными мерами.</span></p>

      <p class=MsoNormal style='margin-top:26.25pt;margin-right:0in;margin-bottom:
8.25pt;margin-left:0in;text-align:justify;line-height:normal'><b><span lang=RU
                                                                       style='font-family:"Calibri Light",sans-serif;color:black'>9. Прочие условия</span></b></p>

      <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
normal'><span lang=RU style='font-family:"Calibri Light",sans-serif;color:#333333'>9.1.
В&nbsp;случае, если какое-либо положение или какая-либо часть положения
Соглашения признаны недействительными или не&nbsp;имеющими юридической силы, остальные
положения и&nbsp;части положений Соглашения остаются в&nbsp;полной силе
и&nbsp;действии.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.2. Все
Приложения к&nbsp;настоящему Соглашению являются его неотъемлемой частью:
Политика конфиденциальности.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.3. Во&nbsp;всем
остальном Стороны договорились руководствоваться действующим законодательством
Российской Федерации.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.4. Продавец
вправе вносить изменения в&nbsp;Соглашение с&nbsp;обязательным размещением
соответствующей информации на&nbsp;Сайте Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.5. По&nbsp;всем
возникающим вопросам Покупатель вправе обращаться в&nbsp;Службу поддержки Исполнителя
по&nbsp;адресу электронной почты </span><span lang=EN-GB style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
          lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
          lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.6.
В&nbsp;соответствии с&nbsp;частью 1&nbsp;статьи 450 Гражданского кодекса РФ, Исполнитель
вправе в&nbsp;любое время в&nbsp;одностороннем порядке изменять условия
настоящего Соглашения. Такие изменения вступают в&nbsp;силу с&nbsp;момента
размещения новой версии Соглашения на&nbsp;сайте. Администрация сайта
направляет Пользователю оферту путем размещения новой редакции в&nbsp;сети
Интернет на&nbsp;сайте Исполнителя. Пользователь сайта понимает
и&nbsp;соглашается с&nbsp;тем, что использование Сайта после изменения
Соглашения рассматривается Исполнителем как принятие этих условий. При
несогласии Пользователя с&nbsp;внесенными изменениями он&nbsp;обязан отказаться
от&nbsp;доступа к&nbsp;Сайту, прекратить использование материалов
и&nbsp;сервисов Сайта.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;соответствии
с&nbsp;пунктами 2, 3&nbsp;статьи 438&nbsp;ГК РФ, в&nbsp;случае совершения
Пользователем любых действий с&nbsp;использованием сайта Исполнителя
Пользователь считается совершившим акцепт оферты Исполнителя о&nbsp;внесении
изменений в&nbsp;настоящее Соглашение.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>Отказ
от&nbsp;исполнения настоящего Соглашения, расторжение настоящего Соглашения,
прекращение действия настоящего Соглашения по&nbsp;иным причинам влечет
прекращение использования возможностей сайта Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.7. В&nbsp;случае
технической невозможности выполнить оказание услуг по&nbsp;Соглашению,
а&nbsp;также в&nbsp;иных случаях по&nbsp;своему усмотрению Исполнитель вправе
в&nbsp;одностороннем порядке отказаться от&nbsp;исполнения настоящего
Соглашения путем направления Пользователю соответствующего уведомления
по&nbsp;электронной почте или размещения информации в&nbsp;общем доступе.
В&nbsp;таком случае Соглашение считается расторгнутым по&nbsp;истечении&nbsp;10
(десяти) календарных дней после отправления/размещения соответствующего
уведомления.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.8. Оформляя
Заказ на&nbsp;Сайте, Пользователь соглашается с&nbsp;получением сообщений после
покупки&nbsp;— о&nbsp;совершенной оплате и&nbsp;о&nbsp;составе заказа.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.9. Регистрируясь
на&nbsp;Сайте, Пользователь соглашается получать на&nbsp;указанный при
регистрации электронный адрес информационные сообщения о&nbsp;новостях,
событиях, подарках и&nbsp;иные сообщения от&nbsp;Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>9.10. Оставляя
в&nbsp;формах на&nbsp;Сайте и&nbsp;в&nbsp;формах любых электронных продуктов,
принадлежащих </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ИП Суднеко А.М. </span><span lang=RU style='font-family:"Calibri Light",sans-serif;
color:#333333'>свой электронный адрес, и, подписываясь на&nbsp;рассылки,
Пользователь соглашается получать разовые, регулярные или автоматические письма
с&nbsp;новостями, событиями, подарками, информацией об&nbsp;акциях, новых
продуктах и&nbsp;иные сообщения от&nbsp;Исполнителя.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>11. Разрешение
споров</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>11.1. Споры
и&nbsp;разногласия, которые могут возникнуть по&nbsp;данному Соглашению,
решаются путем соблюдения досудебного (претензионного) порядка. Срок
рассмотрения претензии&nbsp;— 10&nbsp;календарных дней с&nbsp;момента
ее&nbsp;получения.</span></p>

      <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-family:"Calibri Light",sans-serif;color:#333333'>11.2. Если Стороны
не&nbsp;придут к&nbsp;соглашению, эти споры и&nbsp;разногласия разрешаются
в&nbsp;судебном порядке согласно действующему законодательству Российской
Федерации.</span></p>

    </div>
    <Contacts/>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import Contacts from "@/components/Contacts";
export default {
  name: "Agreement",
  components: {Contacts, PageContainer}
}
</script>

<style scoped>

</style>
