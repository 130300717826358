<template>
  <div class="auth-container modal-container" @click="opened = false"
       :class="{'opened': opened}"
  >
    <div class="auth-wrapper modal-wrapper" @click.stop>
      <svg-icon
          class="close-modal"
          type="mdi"
          :path="mdiClose"
          size="22"
          @click="opened = false"
      />
      <div class="title">Авторизация</div>
      <div class="fields">
        <div
            v-if="step === 'code'"
            class="change-label"
            @click="step = 'email';code = ''"
        >Изменить
        </div>
        <input type="text"
               class="email-field"
               v-model="email"
               :disabled="step === 'code'"
               placeholder="Введите email"
        >
        <input
            v-if="step === 'code'"
            type="number" class="code-field"
            placeholder="Введите проверочный код"
            @input="checkLength"
            maxlength="6"
            v-model="code">
      </div>
      <div style="color: red" v-if="error">{{error}}</div>
      <div class="auth-actions">
        <Button text="Получить код"
                @click="getCode"
                v-if="step === 'email'"
                color="var(--primary-text-color)"
                text_color="#FFFFFF"
        />
        <Button text="Войти"
                @click="login"
                v-if="step === 'code'"
                color="var(--primary-text-color)"
                text_color="#FFFFFF"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mdiClose} from '@mdi/js';

export default {
  name: "Auth",
  data() {
    return {
      email: '',
      code: '',
      error: '',
      codeInputShowed: false,
      secret_key: '',
      step: 'email',
      opened: false,
      mdiClose
    }
  },
  methods: {
    getCode() {
      this.error = '';
      this.$server.request('auth/login', {email: this.email}, (data) => {
        this.secret_key = data.response
        this.step = 'code'
      }, (data) => {
        this.error = data.error || 'Нет соединения с интернетом';
      })
    },
    login() {
      this.error = '';
      this.$server.request('auth/checkCode', {code: this.code, secret_key: this.secret_key}, (data) => {
        this.$server.setToken(data.token)
        this.$store.commit('setUser', data.response)
        this.opened = false
        location.reload();
      }, (data) => {
        this.error = data.error || 'Нет соединения с интернетом';
      })
    },
    checkLength() {
      if (this.code.toString().length === 6) this.login()
    }
  },
  created() {
    this.$eventBus.on('open-auth-modal', () => {
      this.opened = true
    })
  }
}
</script>

<style scoped lang="scss">
.auth-container {
  .auth-wrapper {
    .fields {
      display: flex;
      flex-direction: column;
      //margin-bottom: 16px;

      .change-label {
        margin-bottom: 4px;
        text-decoration: underline;
        cursor: pointer;
      }

      .email-field {
        margin-bottom: 16px;
      }
    }

    .auth-actions {
      display: flex;
      margin-top: 16px;
      justify-content: center;
    }
  }
}
</style>
