<template>
  <page-container class="contacts-page-container">
    <Contacts/>
  </page-container>
</template>

<script>
import Contacts from "@/components/Contacts";
export default {
  name: "ContactsPage",
  components: {Contacts}
}
</script>
<style scoped>

</style>
