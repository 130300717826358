<template>
  <page-container style="max-width: 900px">
    <div class="title">Автор</div>
    <div style="display: flex">
      <div>
        <img style="width: auto;height: 300px;float: left;margin-right: 20px;margin-bottom: 20px;border-radius: 10px"
             :src="$store.state.author.image"/>
      </div>
      <div v-html="$store.state.author.description"></div>
    </div>
    <Contacts/>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import Contacts from "@/components/Contacts";

export default {
  name: "About",
  components: {Contacts, PageContainer},
  mounted() {
    document.getElementById('app').classList.add('hide_bg');
  },
  beforeUnmount() {
    document.getElementById('app').classList.remove('hide_bg');
  }
}
</script>

<style scoped>

</style>
