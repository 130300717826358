<template>
  <page-container class="education-page-container">
    <div class=WordSection1>

    <p class=MsoNormal align=center style='margin-top:4.8pt;margin-right:0in;
margin-bottom:3.35pt;margin-left:0in;text-align:center;line-height:normal'><b><span
        lang=RU style='font-size:30.5pt;font-family:"Arial",sans-serif;color:black'>Оферта</span></b></p>

    <p class=MsoNormal align=center style='margin-top:23.25pt;margin-right:0in;
margin-bottom:8.25pt;margin-left:0in;text-align:center;line-height:normal'><b><span
        lang=RU style='font-size:13.5pt;font-family:"Arial",sans-serif;color:black'>на&nbsp;заключение
договора оказания услуг предоставления доступа к информационно-консультационному
ресурсу</span></b></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Настоящая
Оферта в&nbsp;соответствии с&nbsp;пунктом 2&nbsp;статьи 437 Гражданского
кодекса Российской Федерации является официальным публичным предложением ИП
Суднеко А.М., именуемой в&nbsp;дальнейшем «Исполнитель», заключить с&nbsp;любым
физическим лицом, обладающим необходимой право- и&nbsp;дееспособностью
в&nbsp;соответствии с&nbsp;законодательством Российской Федерации, Договор
оказания услуг &nbsp;предоставления доступа к информационно-консультационному
ресурсу - предоставления доступа к сопутствующим цифровым информационным материалам
(изображениям, видеозаписям, аудиозаписям, текстовым файлам и д.р.)</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:black'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(далее по&nbsp;тексту&nbsp;— «Договор») на&nbsp;условиях,
установленных в&nbsp;настоящей Оферте.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;соответствии
с&nbsp;пунктами 1, 3&nbsp;статьи 438 Гражданского кодекса Российской Федерации
акцептом настоящей Оферты, то&nbsp;есть полным и&nbsp;безоговорочным принятием
ее&nbsp;условий является совокупность следующих действий:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;оплата
заказа, согласно выбранной программе цифровых информационных материалов, размещенной
на сайте </span><span lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>com</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>).</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;выражение
согласия на&nbsp;получение платных услуг доступа к информационно-консультационному
ресурсу предоставления доступа к сопутствующим цифровым информационным
материалам (изображениям, видеозаписям, аудиозаписям, текстовым файлам и д.р.)</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:black'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>на&nbsp;условиях Оферты </span><span lang=RU style='font-size:
12.0pt;font-family:"Calibri Light",sans-serif'>путем нажатия на&nbsp;Сайте
кнопки «Согласен»;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Оплачивая
стоимость услуг Исполнителя, Заказчик:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;подтверждает,
что он&nbsp;ознакомился и&nbsp;согласен с&nbsp;Политикой конфиденциальности
Исполнителя, размещенной на сайте </span><span lang=EN-GB style='font-size:
12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=EN-GB
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'> </span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>).</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;гарантирует
достоверность и&nbsp;актуальность сведений, предоставляемых о&nbsp;себе;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;гарантирует,
что он&nbsp;является совершеннолетним и&nbsp;полностью дееспособным лицом;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;соглашается,
что он&nbsp;самостоятельно несет ответственность за&nbsp;любые последствия,
возникающие в&nbsp;результате указания недостоверных, неактуальных или неполных
сведений о&nbsp;себе.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Лицо,
совершившее акцепт настоящей Оферты, становится и&nbsp;далее именуется
«Заказчиком».</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Исполнитель
вправе в&nbsp;одностороннем порядке изменять условия Оферты, и&nbsp;такие
изменения вступают в&nbsp;силу в&nbsp;момент опубликования новой версии
в&nbsp;сети «Интернет» по&nbsp;адресу </span><span lang=EN-GB style='font-size:
12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=EN-GB
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'> </span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>). При этом в&nbsp;части величины стоимости Услуги за&nbsp;уже
предоставленный доступ к&nbsp;Услуге предшествующая редакция Оферты продолжает
действовать для Сторон без изменений. В&nbsp;остальной части новые положения
имеют обратную силу.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.
ТЕРМИНЫ И&nbsp;ОПРЕДЕЛЕНИЯ.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.1.
Исполнитель&nbsp;— И.П. Суднеко А.М., ОГРНИП 315504700018463, ИНН 071307538652&nbsp;,
адрес (место нахождения): Долгопрудный, Спортивная 11 «Б», нежилое помещение 9,
р/с [40802810940000125214 ].</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.2.
Заказчик&nbsp;— любое физическое лицо, обладающее необходимой право-
и&nbsp;дееспособностью в&nbsp;соответствии с&nbsp;законодательством Российской
Федерации, имеющее намерение вступить&nbsp;/ вступившее в&nbsp;правоотношения
с&nbsp;Исполнителем по&nbsp;оказанию услуг доступа к информационно-консультационному
ресурсу -предоставления доступа к сопутствующим цифровым информационным
материалам (изображениям, видеозаписям, аудиозаписям, текстовым файлам и д.р.)</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:black'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>на&nbsp;условиях, установленных в&nbsp;настоящей Оферте.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.3.
Сайт Исполнителя (Сайт)&nbsp;— информационный ресурс, принадлежащий
Исполнителю, расположенный в&nbsp;сети «Интернет» под доменными именами </span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>com</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>).</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.4.
Информационно-консультационный ресурс (цифровые информационные материалы)&nbsp;—
дистанционная программа информационно-консультационных услуг, оказываемых
посредством сети «Интернет» и&nbsp;представляющая собой совокупность изображений,
текстовых, графических, видео- и&nbsp;иных материалов. Перечень актуальных
цифровых материалов размещен на&nbsp;Сайте по&nbsp;адресу: &nbsp;</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>com</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>). Доступ к цифровым материалам может состоять
из&nbsp;частей&nbsp;— доступ к&nbsp;которым предоставляется поочередно. </span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;зависимости
от&nbsp;цифрового материала доступ Заказчика может предоставляться: а)
в&nbsp;хронологическом порядке по&nbsp;мере готовности к&nbsp;размещению
на&nbsp;Платформе согласно графику, размещенному на&nbsp;странице
соответствующего цифрового материала на&nbsp;Сайте по&nbsp;адресу:&nbsp;</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>com</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>), либо&nbsp;б) в&nbsp;зависимости от&nbsp;выбранного Заказчиком
уже размещенного на платформе цифрового материала либо в) доступ к&nbsp;каждому
следующему цифровому материалу предоставляется после прохождения Заказчиком текущего
цифровому материала.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Содержание
цифрового материала может изменяться Исполнителем в&nbsp;одностороннем порядке,
путем увеличения или изменения количества информации.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.5.
Программа цифрового материала&nbsp;— описание цифрового материала, инструкции,
размещенные на&nbsp;Сайте по&nbsp;адресу: </span><span lang=EN-GB
                                                       style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=EN-GB
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'> </span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>). Указанная информация является неотъемлемой частью настоящего
Договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.6.
Промокод&nbsp;— буквенно-цифровой код, при использовании которого путем
введения в&nbsp;поле на&nbsp;Сайте «У&nbsp;меня есть промокод» Заказчику
предоставляется скидка путем уменьшения стоимости доступа к цифровому материалу,
выбранному Заказчиком при заключении настоящего Договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1.7.
Код доступа&nbsp;— буквенно-цифровой код, подтверждающий оплату потенциальным
Заказчиком стоимости выбранной Программы, является уникальным </span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif'>средством<span
        style='color:#ED7D31'> </span><span style='color:#333333'>доступа к программе
цифрового материала.</span></span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>2.
ОБЩИЕ ПОЛОЖЕНИЯ.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>2.1.
Исполнитель обязуется оказать Заказчику платные услуги по&nbsp;предоставления
доступа к информационно-консультационному ресурсу - предоставления доступа к
сопутствующим цифровым информационным материалам (изображениям, видеозаписям,
аудиозаписям, текстовым файлам и д.р.), выбранной и оплаченной Заказчиком при
заключении настоящего Договора (далее&nbsp;— «Услуги»), а&nbsp;Заказчик
обязуется принять указанные услуги и&nbsp;оплатить&nbsp;их.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>С&nbsp;целью
оказания услуг в&nbsp;соответствии с&nbsp;условиями настоящего Договора
Исполнитель предоставляет Заказчику доступ к&nbsp;материалам в соответствии с выбранной
и оплаченной программой, описанной на сайтах </span><span lang=EN-GB
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=EN-GB
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'> </span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>), в&nbsp;порядке, объеме и&nbsp;в&nbsp;сроки, предусмотренными Программой,
условиями предоставления доступа и настоящим Договором.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>2.2.
Услуги не сопровождаются проведением итоговой аттестации, присвоением
какой-либо квалификации и&nbsp;выдачей документа об&nbsp;образовании.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>2.3.
Исполнитель вправе оказывать Услуги по&nbsp;настоящему Договору лично или
с&nbsp;привлечением третьих лиц.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>2.4.
Стороны согласовали, что, если в&nbsp;течение срока предоставления доступа к информационно-консультационному
ресурсу - предоставления доступа к сопутствующим цифровым информационным
материалам (изображениям, видеозаписям, аудиозаписям, текстовым файлам и д.р.),в
соответствии с Программой, выбранной Заказчиком при заключении настоящего
Договора, от&nbsp;Заказчика не&nbsp;поступит претензий к&nbsp;качеству,
количеству (объему) и&nbsp;срокам оказанных Услуг, платные Услуги считаются
оказанными Исполнителем и&nbsp;принятыми Заказчиком в&nbsp;полном объеме
и&nbsp;в&nbsp;соответствии с&nbsp;условиями настоящего Договора в&nbsp;дату
окончания периода предоставления доступа, указанную в&nbsp;Программе
предоставления доступа к цифровым материалам.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>3.
СТОИМОСТЬ УСЛУГ И&nbsp;ПОРЯДОК РАСЧЕТОВ.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>3.1.
Стоимость Услуг по&nbsp;настоящему Договору составляет стоимость доступа к выбранной
Заказчиком Программе, действующей на&nbsp;дату оплаты Услуг по&nbsp;настоящему
Договору и размещенной на&nbsp;Сайте по&nbsp;адресу: </span><span lang=EN-GB
                                                                  style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=RU
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>
(</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>)<span style='background:yellow'> </span></span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>3.2.
В&nbsp;период проведения Исполнителем акций и&nbsp;конкурсов в&nbsp;порядке
и&nbsp;на&nbsp;условиях, размещенных на&nbsp;Сайте, Заказчику может быть
предоставлен промокод&nbsp;— буквенно-цифровой код, при использовании которого
путем введения в&nbsp;поле на&nbsp;Сайте «У&nbsp;меня есть промокод» Заказчику
предоставляется скидка путем уменьшения стоимости выбранного объема доступа к
цифровым материалам.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Использование
Заказчиком промокода указанным способом подтверждает его ознакомление
и&nbsp;согласие с&nbsp;правилами проведения конкретных акций и&nbsp;конкурсов,
в&nbsp;соответствии с&nbsp;которыми Заказчику был предоставлен промокод.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>При
использовании промокода стоимость Услуг по&nbsp;настоящему Договору составляет
стоимость выбранного Заказчиком при заключении настоящего Договора объема
доступа к цифровым материалам, указанная на&nbsp;Сайте после использования
Заказчиком промокода.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>3.3.
Заказчик оплачивает стоимость Услуг по&nbsp;настоящему Договору электронным способом
при помощи платежной системы (сервиса приема платежей)<a>.</a></span><span
        class=MsoCommentReference><span lang=RU style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif'><a class=msocomanchor id="_anchor_1"
                               onmouseover="msoCommentShow('_anchor_1','_com_1')"
                               onmouseout="msoCommentHide('_com_1')" href="#_msocom_1" language=JavaScript
                               name="_msoanchor_1">[U1]</a>&nbsp;</span></span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Исполнитель
вправе запросить, а&nbsp;Заказчик обязан в&nbsp;течение одного рабочего дня
с&nbsp;момента получения запроса предоставить скан-копии документов,
подтверждающих оплату услуг по&nbsp;настоящему Договору, путем
их&nbsp;направления на&nbsp;электронный адрес&nbsp;</span><span lang=EN-GB
                                                                style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;случае
изменения условий акций и&nbsp;конкурсов, предоставления скидок по&nbsp;иным
основаниям, истечения срока действия промокодов, Заказчик не&nbsp;вправе
требовать от&nbsp;Исполнителя применения скидок, промокодов или иных условий
поощрений и&nbsp;не&nbsp;вправе требовать выплат, возмещения убытков или любого
иного вреда от&nbsp;изменений содержания сайта, включая, но не ограничиваясь
изменениями дизайна, содержания и материалов Программ.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.
ПРАВА И&nbsp;ОБЯЗАННОСТИ СТОРОН</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.1.
Исполнитель обязан:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.1.1.
организовать оказание Услуг в&nbsp;соответствии с&nbsp;условиями настоящего
Договора;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.1.2.
предоставить Заказчику доступ к&nbsp;таким материалам на&nbsp;оплаченный срок,
выполнить иные действия, предусмотренные Программой цифрового материала;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.1.3.
заблаговременно, не&nbsp;позднее, чем за&nbsp;1 (один) рабочий день,
информировать Заказчика об&nbsp;изменениях Программой цифрового материала путем
направления уведомления на&nbsp;адрес электронной почты, указанный Заказчиком
при заполнении регистрационной формы, размещенной на&nbsp;Сайте;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.1.4.
осуществлять консультационную поддержку Заказчика относительно порядка оказания
Услуг путем ответа на&nbsp;сообщения, направляемые Заказчиком на&nbsp;адрес
электронной почты Исполнителя </span><span lang=EN-GB style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.2.
Исполнитель вправе:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.2.1.
в&nbsp;одностороннем порядке вносить изменения в&nbsp;Программу цифровых
материалов. Стороны согласовали, что внесение таких изменений не&nbsp;является
изменением условий настоящего Договора;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.2.2.
получать от&nbsp;Заказчика информацию, необходимую для исполнения своих
обязательств в&nbsp;соответствии с&nbsp;условиями настоящего Договора;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.2.3.
приостановить исполнение своих обязательств по&nbsp;настоящему Договору
в&nbsp;случае неисполнения либо ненадлежащего исполнения Заказчиком возложенных
на&nbsp;него обязанностей;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.2.4.
отказаться от&nbsp;исполнения своих обязательств по&nbsp;настоящему Договору
в&nbsp;порядке и&nbsp;на&nbsp;условиях, предусмотренных разделом 5&nbsp;настоящего
Договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.
Заказчик обязан:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.1.
в&nbsp;порядке и&nbsp;сроки, предусмотренные условиями настоящего Договора,
оплатить Услуги;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.2.
самостоятельно обеспечить техническую возможность пользования Услугами,
в&nbsp;том числе:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>1)
надлежащий доступ в&nbsp;сеть «Интернет»;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>2)
наличие программного обеспечения, совместимого с&nbsp;передачей информации
от&nbsp;Исполнителя и&nbsp;других необходимых средств;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.3.
предоставлять Исполнителю информацию, необходимую для оказания Услуг
в&nbsp;соответствии с&nbsp;условиями настоящего Договора;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.4.
получать Услуги лично, не&nbsp;предоставлять свои аутентификационные данные
третьим лицам для их&nbsp;доступа к&nbsp;цифровым материалам. В&nbsp;случае
утраты, а&nbsp;также в&nbsp;случаях незаконного получения доступа к&nbsp;логину
(электронной почте) третьими лицами, Заказчик обязуется незамедлительно
сообщить об&nbsp;этом Исполнителю путем направления уведомления по&nbsp;адресу </span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>@</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>bk</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>ru</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.&nbsp;До&nbsp;момента
отправки указанного извещения все действия, совершенные с&nbsp;использованием
логина и&nbsp;пароля Заказчика, считаются совершенными Заказчиком.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.5.
Не использовать материалы, переданные Исполнителем с целью тиражирования,
копирования, сохранения, размещения, публикации в&nbsp;общедоступных, закрытых,
открытых источниках для любого круга лиц, иными способами, а также с целями, которые
прямо не&nbsp;указаны в&nbsp;Программе цифровых материалов. Не допускается
использовать доступ или демонстрацию цифрового ресурса для целей, не
предусмотренных Программой цифрового материала.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.6.
Соблюдать законодательство Российской Федерации о&nbsp;защите интеллектуальных и
авторских прав Исполнителя на материалы информационно-консультационного
ресурса, а также сопутствующие цифровые информационные материалы (изображения, видеозаписи,
аудиозаписи, текстовые файлы и д.р.)</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:black'> </span><span lang=RU
                                                                  style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>и&nbsp;нести
ответственность за&nbsp;его нарушение. Использование Заказчиком цифровых
информационных материалов (как в&nbsp;целом, так и&nbsp;фрагментарно)
и&nbsp;прочих разработанных Исполнителем технических решений не&nbsp;означает
передачи (отчуждения) Заказчику и/или любому третьему лицу прав
на&nbsp;результаты интеллектуальной деятельности, как в&nbsp;целом, так
и&nbsp;частично.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.7
Соблюдать этические нормы поведения при использовании
информационно-консультационного ресурса</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.3.8
Неисполнение&nbsp;и (или) ненадлежащее исполнение любой из&nbsp;обязанностей,
предусмотренных пунктами 4.3.4−4.3.7 настоящего Договора, является основанием:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>—&nbsp;для
одностороннего внесудебного отказа Исполнителя от&nbsp;исполнения Договора;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.4.
Заказчик вправе:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.4.1.
лично получить Услуги в&nbsp;соответствии с&nbsp;условиями настоящего Договора;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.4.2.
получить материалы, предусмотренные Программой цифровых информационных
материалов, либо доступ к&nbsp;таким материалам на&nbsp;срок, предусмотренный этой
программой;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.4.3.
отказаться от&nbsp;исполнения настоящего Договора в&nbsp;порядке
и&nbsp;на&nbsp;условиях, предусмотренных разделом 5&nbsp;настоящего Договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>4.4.4.
вносить предложения по&nbsp;улучшению качества оказываемых Услуг, а также
отправлять свои отзывы на электронную почту Исполнителя </span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>@</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>bk</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>ru</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>5.
СРОК ДЕЙСТВИЯ ДОГОВОРА. ИЗМЕНЕНИЕ И&nbsp;ПРЕКРАЩЕНИЕ ДОГОВОРА.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>5.1.
Настоящий Договор действует с&nbsp;даты его заключения, которой считается дата
акцепта Оферты, и&nbsp;действует до&nbsp;даты окончания доступа к выбранному
Заказчиком при заключении настоящего Договора цифровому материалу.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>5.2.
Настоящий Договор может быть изменен или расторгнут по&nbsp;соглашению Сторон
либо по&nbsp;требованию одной из&nbsp;Сторон по&nbsp;основаниям
и&nbsp;в&nbsp;порядке, которые предусмотрены законодательством Российской
Федерации и&nbsp;настоящим Договором.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>5.3.
Исполнитель вправе в&nbsp;одностороннем внесудебном порядке отказаться
от&nbsp;настоящего Договора в&nbsp;случае неисполнения&nbsp;и (или)
ненадлежащего исполнения Заказчиком любой из&nbsp;обязанностей, предусмотренных
пунктами настоящего Договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>При
расторжении Договора по&nbsp;настоящему основанию Исполнитель выплачивает
Заказчику ранее уплаченную последним стоимость Услуг за&nbsp;вычетом расходов
Исполнителя, связанных с&nbsp;оказанием Услуг. Стоимость расходов Исполнителя
определяется пропорционально периоду полученного доступа к цифровому ресурсу. </span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;случае
расторжения Договора по&nbsp;предусмотренным в&nbsp;п.&nbsp;5.3 настоящего
Договора основаниям Договор считается расторгнутым с&nbsp;даты направления
уведомления о&nbsp;его расторжении Исполнителем на&nbsp;адрес электронной
почты, указанный Заказчиком при заполнении регистрационной формы, размещенной
на&nbsp;Сайте с&nbsp;одновременной блокировкой доступа к&nbsp;информационно
цифровому ресурсу.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Одновременно
с&nbsp;уведомлением о&nbsp;расторжении Договора Исполнитель направляет
на&nbsp;адрес электронной почты Заказчика форму Заявления на&nbsp;возврат денежных
средств. Заказчик не&nbsp;позднее&nbsp;5 (пяти) рабочих дней с&nbsp;момента
получения формы Заявления от&nbsp;Исполнителя обязуется корректно заполнить,
подписать и&nbsp;направить ее&nbsp;Исполнителю в&nbsp;сканированном виде
на&nbsp;электронный адрес </span><span lang=RU><a
        href="mailto:annasudneko@bk.ru"><span lang=EN-GB style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif'>annasudneko</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif'>@</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif'>bk</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif'>.</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif'>ru</span></a></span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> </span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>и&nbsp;в&nbsp;оригинале на&nbsp;почтовый адрес: Долгопрудный,
Спортивная 11»б», нежилое помещение 9. Денежные средства перечисляются
Исполнителем по&nbsp;реквизитам, указанным в&nbsp;заявлении Заказчиком,
в&nbsp;течение 10&nbsp;дней.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Отсутствие
заявления Заказчика на&nbsp;возврат денежных средств не&nbsp;является
препятствием для расторжения договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>5.4.
Заказчик вправе в&nbsp;одностороннем внесудебном порядке отказаться
от&nbsp;настоящего Договора путем направления уведомления на&nbsp;адрес
электронной почты Исполнителя&nbsp;</span><span lang=EN-GB style='font-size:
12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'> </span><span lang=RU
                                                        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>с&nbsp;указанием
причин отказа от&nbsp;Договора, а&nbsp;также своих данных, позволяющих
Исполнителю однозначно верифицировать Заказчика, реквизитов и&nbsp;иной
информации, необходимой для возврата денежных средств. </span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;этом
случае настоящий Договор считается расторгнутым с&nbsp;первого рабочего дня,
следующего за&nbsp;днем направления такого уведомления.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>При
расторжении Договора по&nbsp;настоящему основанию Исполнитель выплачивает
Заказчику стоимость оплаченных Услуг за&nbsp;вычетом стоимости фактически оказанных
Услуг и&nbsp;расходов, связанных с&nbsp;оказанием Услуг, фактически понесенных
Исполнителем на&nbsp;дату расторжения Договора.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>В&nbsp;случае
отказа Заказчика от&nbsp;Договора и&nbsp;наличия у&nbsp;него права
на&nbsp;возврат денежных средств Исполнитель направляет Заказчику форму заявления
о&nbsp;возврате денежных средств (далее&nbsp;— Заявление). Заказчик
не&nbsp;позднее&nbsp;5 (пяти) рабочих дней с&nbsp;момента получения формы
Заявления от&nbsp;Исполнителя обязуется корректно заполнить, подписать
и&nbsp;направить ее&nbsp;Исполнителю в&nbsp;сканированном виде
на&nbsp;электронный адрес&nbsp;</span><span lang=EN-GB style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>@</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>bk</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>ru</span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>5.5.
Услуги предоставляются в&nbsp;состоянии «как есть». Исполнитель не&nbsp;несет
ответственности за&nbsp;неисполнение или ненадлежащее исполнение обязательств
по&nbsp;настоящему Договору, а&nbsp;также за&nbsp;возможный ущерб, возникший
в&nbsp;результате:</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>●
несоответствия услуги целям, ожиданиям и&nbsp;требованиям Заказчика;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>●
недостижения Заказчиком ожидаемых результатов;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>●
сбоев в&nbsp;работе программного обеспечения, вызванных ошибками в&nbsp;коде,
компьютерными вирусами и&nbsp;иными посторонними фрагментами кода
в&nbsp;программном обеспечении;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>●
отсутствия (невозможности установления, прекращения и&nbsp;пр.)
интернет-соединений;</span></p>

    <p class=MsoNormal style='margin-bottom:9.0pt;line-height:normal'><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:9.0pt;line-height:normal'><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>6. ОТВЕТСТВЕННОСТЬ СТОРОН</span></p>

    <p class=MsoNormal style='margin-bottom:9.0pt;text-align:justify;line-height:
normal'><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>6.1. Исполнитель не несет ответственности за невозможность предоставления
услуг Заказчику по причинам, связанным с нарушением работы Интернет-канала,
оборудования или программного обеспечения со стороны Заказчика, а также по
любым другим причинам, препятствующим получению Заказчиком услуг, возникшим по
вине Заказчика.&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:9.0pt;text-align:justify;line-height:
normal'><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>6.2. Заказчик обязан предоставить полные и правильные данные при
оформлении регистрации в случае, когда заявка на регистрацию содержит
недостоверные либо неполные данные, Исполнитель не несет ответственности перед
Заказчиком за предоставление информационных материалов по ошибочно указанным
данным не Заказчику, а третьим лицам.&nbsp;</span></p>

    <p class=MsoNormal style='margin-bottom:9.0pt;text-align:justify;line-height:
normal'><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>6.4. Исполнитель не несет ответственности за то, каким образом
Заказчик использовал полученную в ходе полученного доступа к цифровым
материалам информацию, и за результаты ее использования Заказчиком.&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>6.5.
Совокупная ответственность Исполнителя по договору Оферты, по любому иску или
претензии в отношении договора Оферты или его исполнения ограничивается суммой
платежа, уплаченного Исполнителю Заказчиком по договору Оферты</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>6.6.
За нарушение распространение (опубликование, размещение на Интернет-сайтах,
копирование, передачу или перепродажу третьим лицам) в коммерческих или
некоммерческих целях, а также использование их каким-либо иным образом, кроме
как предусмотренных в программах, описанных на сайтах </span><span lang=EN-GB
                                                                   style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=RU
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>,
</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'> <span
        lang=RU>и предоставляемых Исполнителем в рамках настоящего договора Оферты
материалов, , Исполнитель несет ответсвенность в соответсвии с
законодательством РФ</span></span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>6.7.
</span><span lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#2C2D2E;'>Стороны освобождаются от ответственности за
неисполнение или ненадлежащее исполнение обязательств по Договору на время
действия непреодолимой силы. Под непреодолимой силой понимаются чрезвычайные и
непреодолимые при данных условиях обстоятельства, препятствующие исполнению
своих обязательств сторонами по настоящему Договору. К ним относятся стихийные
явления (землетрясения, наводнения и т. п.), обстоятельства общественной жизни
(военные действия, чрезвычайные положения, крупнейшие забастовки, эпидемии и т.
п.), запретительные меры государственных органов (запрещение перевозок,
валютные ограничения, международные санкции запрета на торговлю и т. п.). В
течение этого времени стороны не имеют взаимных претензий, и каждая из сторона
принимает на себя свой риск последствия форс- мажорных обстоятельств.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>&nbsp;</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>7.
ПЕРСОНАЛЬНЫЕ ДАННЫЕ</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>При
заключении настоящего Договора Заказчик дает свое согласие на&nbsp;обработку
(систематизацию, накопление, хранение, уточнение, использование, уничтожение)
предоставленных им&nbsp;при регистрации на&nbsp;сайтах </span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=RU
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>,
</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'> и&nbsp;в&nbsp;ходе оказания Услуг персональных данных согласно
Федеральному закону от&nbsp;27.06.2006 №&nbsp;152-ФЗ&nbsp;«О&nbsp;персональных
данных» в&nbsp;целях заключения настоящего Договора, а&nbsp;также его
последующего исполнения на&nbsp;условиях, предусмотренных Политикой
конфиденциальности, размещенной в&nbsp;соответствующем разделе на&nbsp;сайте
Исполнителя по&nbsp;адресу: </span><span lang=EN-GB style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>annasudneko</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>com</span><span lang=RU
                                                          style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>,
</span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudnek</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>щ.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>ru</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>, </span><span style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>.</span><span
        style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>pro</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>Настоящее
согласие может быть в&nbsp;любое время отозвано Заказчиком посредством
направления уведомления по&nbsp;электронной почте Исполнителя </span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>@</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>bk</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>ru</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>.&nbsp;С&nbsp;учетом
того, что данное согласие необходимо для корректного исполнения Договора
со&nbsp;стороны Исполнителя, в&nbsp;случае отзыва согласия по&nbsp;настоящему
пункту Заказчик вправе расторгнуть договор в&nbsp;одностороннем (внесудебном)
порядке.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>8.
ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>8.1.
К&nbsp;отношениям между Заказчиком и&nbsp;Исполнителем подлежит применению
право Российской Федерации.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>8.2.
В&nbsp;случае возникновения любых разногласий между Заказчиком
и&nbsp;Исполнителем относительно исполнения каждой из&nbsp;сторон условий
Договора, а&nbsp;также любых иных разногласий такие разногласия должны быть
урегулированы с&nbsp;применением обязательного досудебного претензионного
порядка. Исполнитель обязуется направить Заказчику претензию в&nbsp;электронном
виде на&nbsp;адрес электронной почты, указанный Заказчиком при регистрации
на&nbsp;Сайте. Заказчик обязуется направить Исполнителю претензию
в&nbsp;электронном виде на&nbsp;адрес электронной почты&nbsp;</span><span
        lang=EN-GB style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>annasudneko</span><span lang=RU style='font-size:12.0pt;
font-family:"Calibri Light",sans-serif;color:#333333'>@</span><span lang=EN-GB
                                                                    style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>bk</span><span
        lang=RU style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;
color:#333333'>.</span><span lang=EN-GB style='font-size:12.0pt;font-family:
"Calibri Light",sans-serif;color:#333333'>ru</span><span lang=RU
                                                         style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>,
а&nbsp;также продублировать претензию в&nbsp;письменном виде на&nbsp;адрес
Исполнителя, указанный в&nbsp;разделе 1&nbsp;настоящей Оферты. Срок ответа
на&nbsp;претензию&nbsp;— 30&nbsp;(тридцать) рабочих дней со&nbsp;дня
ее&nbsp;получения. Если Законодательством РФ&nbsp;установлен меньший срок,
то&nbsp;применяется срок, установленный законодательством. При несоблюдении
любой из&nbsp;сторон всех перечисленных выше условий обязательный претензионный
порядок не&nbsp;считается соблюденным.</span></p>

    <p class=MsoNormal style='margin-top:10.1pt;margin-right:0in;margin-bottom:
0in;margin-left:0in;text-align:justify;line-height:normal'><span lang=RU
                                                                 style='font-size:12.0pt;font-family:"Calibri Light",sans-serif;color:#333333'>8.3.
Во&nbsp;всем остальном, что не&nbsp;предусмотрено настоящим Договором, Стороны
руководствуются действующим законодательством Российской Федерации.</span></p>

    <p class=MsoNormal><span lang=RU>&nbsp;</span></p>

    <p class=MsoNormal><span lang=RU>&nbsp;</span></p>

    <p class=MsoNormal><span lang=RU>&nbsp;</span></p>

    </div>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
export default {
  name: "Offer",
  components: {PageContainer}
}
</script>

<style scoped>

</style>
