<template>
  <page-container style="width: calc(100% - 40px)">
    <div class="cubes-page-container">
      <div class="cube-types">
        <Button
            v-for="(cube, i) in cubes"
            :key="i"
            :text="'Кубик '+(+cube ? 'D'+cube : cube)"
            :color="d==cube? 'var(--primary-text-color)':'#fff'"
            :text_color="d==cube ? '#fff':'var(--primary-text-color)'"
            @click="d==cube ? throwCube3D() : selectCube({d:cube, t: $route.query.t ||'3D'})"
        />
      </div>
      <div class="cube-types">
        <Button @click="selectCube({d:$route.query.d || 6, t: type ||'3D'})" v-for="type in types"
                :color="t==type? 'var(--primary-text-color)':'#fff'"
                :text_color="t==type? '#fff':'var(--primary-text-color)'" :key="type" :text="type"/>
      </div>
      <div v-if="t=='3D'" class="rolling-field-container">
        <div ref="field" id="ThreeJS" style="position: absolute; left:0; top:0; z-index: 10"></div>
      </div>
      <div class="rolling-field-container" style="display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;padding-top: 60px" v-else>
        <div :class="{done}" style="font-size: 96px;padding-bottom: 40px">{{ number }}</div>
        <Button @click="throwCube2D()" style="width: 100px;margin-bottom: 40px" text="Бросить кубик"/>
      </div>
    </div>
  </page-container>
</template>

<script>
import {init} from '@'
import Button from "@/components/Button";

export default {
  name: "Cubes",
  components: {Button},
  data() {
    return {
      d: this.$route.query.d || 6,
      t: this.$route.query.t || '3D',
      types: ['3D', '2D'],
      number: '',
      done: false,
      cubes: ['Да/нет', 4, 6, 8, 10, 12, 20]
    }
  },
  methods: {
    throwCube2D(timeout) {
      if (!timeout) {
        this.number = '';
        this.done = false;
        timeout = 10;
      }
      let max = parseInt(this.d) || 2;
      this.number = Math.ceil(Math.random() * max);
      if (max === 2) this.number = this.number === 1 ? 'Да' : 'Нет';
      if (timeout < 600) setTimeout(() => this.throwCube2D(Math.pow(timeout, 1.1)), timeout);
      else this.done = true;
    },
    throwCube3D() {
      this.$refs.field.querySelector('canvas').click();
    },
    selectCube(params) {
      let path = [];
      for (let i in params) path.push(`${i}=${params[i]}`);
      window.location.href = '/cubes?' + path.join('&');
    },
  },
  mounted() {
    if (this.t == '3D') {
      init(this.$route.query.d || 6);
      this.throwCube3D();
    } else {
      this.throwCube2D();
    }
    document.getElementById('app').classList.add('hide_bg');
  },
  beforeUnmount() {
    document.getElementById('app').classList.remove('hide_bg');
  }
}
</script>

<style scoped lang="scss">
.cubes-page-container {
  height: 80vh;
}

.cube-types {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;

  .button {
    //margin-right: 16px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.done {
  animation: glow 1s ease-in-out infinite alternate;
}

@-webkit-keyframes glow {
  0% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #2e00e6, 0 0 40px #6300e6, 0 0 50px #c300e6, 0 0 60px #e200e6, 0 0 70px #e600ca;
  }

  100% {
    text-shadow: 0 0 20px #fff, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6, 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}


.rolling-field-container {
  height: calc(100% - 87px);
  //max-height: calc(100vh - 240px);
  position: relative;
  overflow: hidden;
}
</style>
