import {createStore} from "vuex";

export default createStore({
    state: {
        user: {cart:{items:[]}},
        shuffle(array) {
            let currentIndex = array.length,  randomIndex;

            // While there remain elements to shuffle.
            while (currentIndex != 0) {

                // Pick a remaining element.
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;

                // And swap it with the current element.
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }

            return array;
        },
        pieces: [
            {
                id: '1',
                img: '/img/pieces/piece.png',
            },
            {
                id: '2',
                img: '/img/pieces/piece_2.png',
            },
            {
                id: '3',
                img: '/img/pieces/piece_3.png',
            },
            {
                id: '4',
                img: '/img/pieces/piece_4.png',
            },
            {
                id: '5',
                img: '/img/pieces/piece_5.png',
            },
            {
                id: '6',
                img: '/img/pieces/piece_6.png',
            },
            {
                id: '7',
                img: '/img/pieces/piece_7.png',
            },
            {
                id: '8',
                img: '/img/pieces/piece_8.png',
            },
        ],
    },
    mutations: {
        setUser(state, user) {
            state.user = user
        }
    }
})
