<template>
  <page-container class="meta-cards-container">
    <div class="title">Метафорические карты</div>
    <div class="meta-decks-list">
      <ItemSelect class="meta-deck"
                  v-for="deck in decks"
                  :key="deck.id"
                  :preview="deck.back"
                  :label="deck.title"
      />
    </div>
  </page-container>
</template>

<script>
import ItemSelect from "@/components/ItemSelect";

export default {
  name: "Decks",
  components: {ItemSelect},
  data() {
    return {
      decks: []
    }
  },
  methods: {
    getDecks() {
      this.$server.request('game/decks', {}, (data) => {
        this.decks = data.response
      })
    },
  },
  mounted() {
    this.getDecks()
  }
}
</script>

<style scoped lang="scss">
.meta-decks-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .meta-deck {
    width: 220px;
    margin-right: 48px;
    cursor: pointer;

    .deck-back {
      width: 100%;
      height: 350px;
      margin-bottom: 8px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}
</style>
