<template>
  <div :style="styles"
       class="piece onField"
       ref="piece"
       @mousedown="dragMouseDown" @touchstart="dragMouseDown" style="touch-action: none;">
    <img :src="`/img/pieces/piece_${id}.png?v=2`" alt="">
  </div>
</template>

<script>
export default {
  name: "Piece",
  props: {
    id: {
      type: Number
    },
    value: {
      type: Object,
    },
  },
  computed: {
    styles() {
      return {
        position: this.position.pa ? 'absolute' : 'static',
        'z-index': this.position.z,
        top: this.position.y + 'px',
        left: this.position.x + 'px',
      }
    }
  },
  watch: {
    value() {
      console.log('piece', this.position);
      this.position = this.value;
    },
  },
  data() {
    return {
      position: this.value || {
        z: 2,
        y: 0,
        x: 0,
        pa: 0,
      }

    }
  },
  methods: {
    dragMouseDown(event) {
      event.preventDefault()
      window.maxZIndex++;
      this.updatePosition({z: window.maxZIndex});
      document.onmousemove = this.elementDrag
      document.ontouchmove = this.elementDrag
      document.onmouseup = this.closeDragElement
      document.ontouchend = this.closeDragElement
    },
    updatePosition(params) {
      for (let i in params) this.position[i] = params[i];
      this.$emit('change', this.position);
    },
    elementDrag(event) {
      event.preventDefault()
      let coords = event.clientX ? event : event.touches[0];
      this.updatePosition({
        pa: 1,
        x: coords.clientX - (this.$refs.piece.offsetWidth / 2),
        y: coords.clientY - (this.$refs.piece.offsetHeight / 2)
      })
    },
    closeDragElement() {
      document.onmouseup = null
      document.onmousemove = null
      document.ontouchmove = null
      document.ontouchend = null
    },
  }
}
</script>

<style scoped lang="scss">
.piece {
  height: 48px;
  position: relative;
  padding-right: 10px;
  z-index: 1;
  cursor: pointer;
  min-width: 20px;
  width: 20px;

  &.onField {
    position: absolute;
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
  }
}
</style>
