<template>
  <page-container>
    <div class="title">Магазин онлайн-версий игр</div>
  </page-container>
</template>

<script>
export default {
  name: "Shop"
}
</script>

<style scoped>

</style>
