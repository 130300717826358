<template>
  <div class="app-wrapper">
    <router-link
        to="/"
        style="position:absolute;z-index: 10"
        class="navbar-logo">
      <img style="width: 64px !important;" src="/img/logo.png" alt="">
    </router-link>
    <Navbar/>
    <Container/>
    <div class="auth-container modal-container" @click="error = ''"
         :class="{'opened': !!error}"
    >
      <div class="auth-wrapper modal-wrapper" @click.stop
           style="min-width: 300px;min-height: 200px;justify-content: flex-start;align-items: center;">
        <h1>Ошибка</h1>
        <p style="margin-top: 40px"> {{ error }}</p>
        <Button @click="error=''" style="margin-top: 40px;width: calc(100% - 40px)" text="Ок"/>
      </div>
    </div>
    <div class="auth-container modal-container" @click="confirm.title = ''"
         :class="{'opened': !!confirm.title}"
    >
      <div class="auth-wrapper modal-wrapper" @click.stop
           style="min-width: 300px;justify-content: flex-start;align-items: center;">
        <h1 style="font-size: 28px">{{ confirm.title }}</h1>
        <div style="display: flex;margin-top: 20px">
          <Button color="white" text_color="var(--primary-text-color)" @click="confirm.title=''" style="width: 100px"
                  text="Нет"/>
          <Button @click="confirm.yes();confirm.title = ''"
                  style="width: 100px" text="Да"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Container from "@/components/Container";
import Auth from "@/pages/Auth";
import Button from "@/components/Button";

export default {
  name: 'App',
  components: {
    Button,
    Navbar,
    Container,
    Auth
  },
  data() {
    return {
      error: '',
      confirm: {
        title: '',
        yes: () => {
        },
      },

    }
  },
  methods: {
    loadUser() {
      this.$server.request('user/get', {}, data => {
        if (data.response) this.$store.state.user = data.response;
        this.$store.state.author = data.author;
      }, data => {
        if (data.error !== 'user is not logged in') this.$server.setToken('');
      });
    },
    addItemToCart(item) {
      if (!this.$server.token) return this.$eventBus.emit('open-auth-modal')
      this.$server.request('user/addToCart', item, data => {
        if (data.response) this.$store.state.user = data.response;
      }, data => {
        this.error = data.error || 'Нет соединения...';
      });
    }
  },
  mounted() {
    this.$eventBus.on('show-error', error => this.error = error);
    this.$eventBus.on('load-user', () => this.loadUser());
    this.$eventBus.on('add-to-cart', item => this.addItemToCart(item));
    this.$eventBus.on('confirm', item => this.confirm = {title: item[0], yes: item[1]});
    this.loadUser();
  }
}
</script>

<style>
@import "assets/styles.scss";
</style>
