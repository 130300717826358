<template>
  <div @click="$refs.fileInput.click()" class="image"
       :style="`background-image:url(${src || '/img/empty_image.png'});${src?'background-size: cover;':''}`">
    <input accept="image/*" @change="upload" ref="fileInput" type="file" style="display: none">
  </div>
</template>

<script>
export default {
  name: "ImageInput",
  props: {
    modelValue: {default: "", type: String},
  },
  data() {
    return {
      src: this.modelValue
    }
  },
  watch: {
    modelValue() {
      this.src = this.modelValue;
    }
  },
  methods: {
    upload() {
        if (this.$refs.fileInput.files[0]) this.$server.request('auth/uploadFile', {file: this.$refs.fileInput.files[0]}, data => {
          this.src = data.response;
          this.$emit('update:modelValue', this.src);
        })
    },
  },
}
</script>
