<template>
  <page-container style="max-width: 600px">
    <div class="title">Видео-курсы</div>
    <div style="margin-top: 20px;
    display: flex;flex-wrap: wrap;
    gap: 20px;">
      <router-link v-for="course in courses" :key="course.id" :to="`/courses/${course.id}`">
        <div style="width: 300px;border-radius: 8px;overflow: hidden;background: white;">
          <div style="height: 300px;background-repeat: no-repeat;
    background-position: center;background-color: #eee;
    background-size: 50%;"
               :style="`background-image:url(${course.preview || '/img/empty_image.png'});${course.preview?'background-size: cover;':''}`"></div>
          <div style="padding: 5px 10px;">{{ course.title }}</div>
        </div>
      </router-link>
    </div>
  </page-container>
</template>

<script>

export default {
  name: "Courses",
  data() {
    return {
      courses: [],
    }
  },
  methods: {
    load() {
      this.$server.request('course/get', {}, data => this.courses = data.response);
    },
  },
  mounted() {
    this.load();
  }
}
</script>
<style lang="scss">

</style>
