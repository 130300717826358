<template>
  <page-container class="education-page-container">
    <div class="title">Сведения об образовательной организации</div>
    <p class="p1"><strong>Основные сведения:</strong></p>
    <p class="p1"><i>Сведения об ИП:</i></p>
    <p class="p2"><strong>Полное наименование:</strong><span class="s1"></span></p>
    <p class="p3">Индивидуальный предприниматель Суднеко Анна Михайловна</p>
    <p class="p3">ИНН 071307538652</p>
    <p class="p3">ОГРНИП 315504700018463</p>
    <p class="p2"><strong>Cокращенное наименование</strong><span class="s1">:</span></p>
    <p class="p3">ИП Суднеко Анна Михайловна</p>
    <p class="p2"><strong>Дата регистрации</strong><span class="s1">: 18.11.2015 г.</span></p>
    <p class="p1"><strong>Структура и органы управления образовательной организацией:</strong></p>
    <p class="p2"><i>Наименование представительств и филиалов образовательной организации:</i></p>
    <p class="p3">Отсутствуют</p>
    <p class="p1"><i><strong>Руководство:</strong></i></p>
    <p class="p3">Суднеко Анна Михайловна</p>
    <p class="p1"><i><strong>Педагогические работники</strong></i><span class="s1"><strong>:</strong></span></p>
    <p class="p3">Суднеко Анна Михайловна</p>
    <p class="p2"><strong>Образование:</strong></p>
    <p class="p2">Кабардино-Балкарский Государственный Университет:</p>
    <p class="p2">Присуждена степень: Бакалавр химии</p>
    <p class="p2">Московский Психолого-Социальный Институт (в настоящее время МПСУ)</p>
    <p class="p2">Присуждена квалификация: Психолог. Преподаватель психологии по специальности «Психология»</p>
    <p class="p2">Профессиональной переподготовка:</p>
    <p class="p2">Российский Государственный Социальный Университет:</p>
    <p class="p2">Диплом о профессиональной переподготовке. Присвоена квалификация: Клинический психолог</p>
    <p class="p2">Национальный центральный институт развития дополнительного образования:</p>
    <p class="p2">Диплом о профессиональной переподготовке по программе дополнительного профессионального образования. Присвоена квалификация: Арттерапевт</p>
    <p class="p2">Диплом о профессиональной переподготовке по программе дополнительного профессионального образования "Аналитическая психология"</p>
    <p class="p2">Диплом о профессиональной переподготовке. «Супервизия в психологическом консультировании и психотерапии. Обучение супервизоров».</p>
    <p class="p2">Диплом о профессиональной переподготовке "Психосинтез в практике психолога"</p>
    <p class="p2">Стаж работы: более 20 лет (с 02.02.2004г)</p>
    <p class="p1"><i><strong>Место нахождения образовательной организации:</strong></i></p>
    <p class="p3">В&nbsp;настоящее время все образовательные программы реализуются с&nbsp;применением электронного обучения, дистанционных образовательных технологий.</p>
    <p class="p3"><i><span class="s2"><strong>Режим и график работы: </strong></span></i>понедельник - пятница с 10:00 — 19:00, сб, вс - выходные</p>
    <p class="p4"><i><strong>Контактный телефон: </strong></i><span class="s1">+ 79261782525</span></p>
    <p class="p1"><i><strong>Адрес электронной почты</strong>:<strong> </strong></i><span class="s1">annasudneko@bk.ru</span></p>
    <p class="p1"><i><strong>Адреса осуществления образовательной деятельности</strong>:</i></p>
    <p class="p3">ИП Суднеко Анна Михайловна осуществляет образовательную деятельность исключительно с использованием дистанционных образовательных технологий и электронного обучения, посредством электронной информационно-образовательной среды.</p>
    <p class="p1"><i><strong>Образование:</strong></i></p>
    <p class="p5"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/Выписка_из_реестра_лицензий_№_Л035_01255_50_01056617_2.pdf"><span class="s3">Выписка_из_реестра_лицензий_№_Л035_01255_50_01056617_2.pdf</span><span class="s3 s4"></span></a></p>
    <p class="p3">Организация предоставляет услуги в&nbsp;сфере дополнительного образования (подвид: дополнительное образование для детей и&nbsp;взрослых) на&nbsp;основании&nbsp;лицензии на&nbsp;осуществление образовательной деятельности, выданной Министерством образования Московской области, дата предоставления лицензии: 12.02.2024, регистрационный номер лицензии: № Л035-01255-50/01056617, номер и&nbsp;дата приказа (распоряжения) лицензирующего органа о&nbsp;предоставлении лицензии: Распоряжение №РС/Л-143 от 12.02.2024</p>
    <p class="p3"><strong>Реализуемый вид образования:</strong> дополнительное образование (подвид&nbsp;— дополнительное образование для детей и&nbsp;взрослых).</p>
    <p class="p3"><strong>Формы обучения:</strong></p>
    <p class="p3">очная (с&nbsp;использованием электронного обучения, дистанционных образовательных технологий).</p>
    <p class="p3">очно-заочная (с&nbsp;использованием электронного обучения, дистанционных образовательных технологий).</p>
    <p class="p3">заочная (с&nbsp;использованием электронного обучения).</p>
    <p class="p3"><strong>Образовательная деятельность:</strong> ведется на&nbsp;русском языке.</p>
    <p class="p3"><strong>Сроки обучения:</strong> определяются образовательной программой. Нормативные сроки обучения отсутствуют.</p>
    <p class="p3"><strong>Численность обучающихся по&nbsp;реализуемой образовательной программе:</strong></p>
    <p class="p3">общая численность обучающихся&nbsp;— определяется по&nbsp;фактическому формированию учебных групп.</p>
    <p class="p3">численность обучающихся за&nbsp;счет бюджетных ассигнований федерального бюджета&nbsp;— нет (за&nbsp;счет бюджетных ассигнований обучение не&nbsp;осуществляется).</p>
    <p class="p3">численность обучающихся за&nbsp;счет бюджетных ассигнований бюджетов субъектов Российской Федерации&nbsp;— нет (за&nbsp;счет бюджетных ассигнований обучение не&nbsp;осуществляется).</p>
    <p class="p3">численность обучающихся за&nbsp;счет бюджетных ассигнований местных бюджетов&nbsp;— нет (за&nbsp;счет бюджетных ассигнований обучение не&nbsp;осуществляется).</p>
    <p class="p3">численность обучающихся по&nbsp;договорам об&nbsp;образовании, заключаемых при приеме на&nbsp;обучение за&nbsp;счет средств физического&nbsp;и (или) юридического лица, - определяется по&nbsp;фактическому формированию учебных групп.</p>
    <p class="p3"><strong>Информация о&nbsp;реализуемых образовательных программах (курсах):</strong></p>
    <p class="p1"><i><strong>Реализуемые образовательные программы</strong><span class="s1"><strong>:</strong></span></i></p>
    <p class="p3"><i><strong>Дополнительная общеобразовательная программа - дополнительная общеразвивающая программа «ВЕДЕНИЕ ПСИХОЛОГИЧЕСКИХ ТРАНСФОРМАЦИОННЫХ ИГР»</strong></i></p>
    <p class="p3">(направленность: социально-гуманитарная)</p>
    <p class="p3">Форма обучения: дистанционная, с применением исключительно электронного обучения, дистанционных образовательных программ</p>
    <p class="p3">Нормативный срок обучения: 24 академических часа.</p>
    <p class="p3">Срок реализации: 4 недели</p>
    <p class="p3">Уровень образования: Дополнительное образование детей и взрослых</p>
    <p class="p5"><span class="s5"></span><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/ДОПОЛНИТЕЛЬНАЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ПРОГРАММА.pdf"><span class="s5 s6">ДОПОЛНИТЕЛЬНАЯ ОБЩЕОБРАЗОВАТЕЛЬНАЯ ПРОГРАММА.pdf</span></a></p>
    <p class="p3"><i><span class="s2">Формы контроля: </span></i>текущий контроль, творческие и практические задания</p>
    <p class="p3"><i><strong>Дополнительная общеобразовательная программа - дополнительная общеразвивающая программа «МАНДАЛЫ. РЕСУРСЫ. ВОЗМОЖНОСТИ. СМЫСЛЫ.»</strong></i></p>
    <p class="p3">(направленность: социально-гуманитарная)</p>
    <p class="p3">Форма обучения: дистанционная, с применением исключительно электронного обучения, дистанционных образовательных программ</p>
    <p class="p3">Нормативный срок обучения: 40 академических часов.</p>
    <p class="p3">Срок реализации: 4 недели</p>
    <p class="p3">Уровень образования: Дополнительное образование детей и взрослых</p>
    <p class="p3"><span class="s7"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/Программа Мандалы.pdf">Программа «Мандалы»</a></span></p>
    <p class="p3"><i><span class="s2">Формы контроля: </span></i>текущий контроль, творческие и практические задания</p>
    <p class="p3"><i><strong>Дополнительная общеобразовательная программа - дополнительная общеразвивающая программа «МЕТАФОРИЧЕСКИЕ КАРТЫ. ВОЗМОЖНОСТИ И РЕСУРСЫ»</strong></i></p>
    <p class="p3">(направленность: социально-гуманитарная)</p>
    <p class="p3">Форма обучения: дистанционная, с применением исключительно электронного обучения, дистанционных образовательных программ</p>
    <p class="p3">Нормативный срок обучения: 36 академических часов.</p>
    <p class="p3">Срок реализации: 4 недели</p>
    <p class="p3">Уровень образования: Дополнительное образование детей и взрослых</p>
    <p class="p3"><span class="s7"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/ПРОГРАММА КАРТЫ.pdf">Программа «Карты»</a></span></p>
    <p class="p3"><i><span class="s2">Формы контроля: </span></i>текущий контроль, творческие и практические задания</p>
    <p class="p3"><i><strong>Дополнительная общеобразовательная программа - дополнительная общеразвивающая программа «МЕТОД «АНАЛИЗ. МОТИВАЦИЯ. ТРАНСФОРМАЦИЯ. ИНТЕГРАЦИЯ» В ТРАНСФОРМАЦИОННЫХ ИГРАХ И В РАБОТЕ С МЕТАФОРИЧЕСКИМИ КАРТАМИ»</strong></i></p>
    <p class="p3">(направленность: социально-гуманитарная)</p>
    <p class="p3">Форма обучения: дистанционная, с применением исключительно электронного обучения, дистанционных образовательных программ</p>
    <p class="p3">Нормативный срок обучения: 40 академических часов.</p>
    <p class="p3">Срок реализации: 4 недели</p>
    <p class="p3">Уровень образования: Дополнительное образование детей и взрослых</p>
    <p class="p3"><span class="s7"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/Программа МЕТОД АМТИ.pdf">Программа «МЕТОД АМТИ»</a></span></p>
    <p class="p3"><i><span class="s2">Формы контроля: </span></i>текущий контроль, творческие и практические задания</p>
    <p class="p2"><i><strong>Материально-техническое обеспечение и&nbsp;оснащенность образовательного процесса:</strong></i></p>
    <p class="p3">В&nbsp;ходе обучения для взаимодействия преподавателя и&nbsp;обучающихся используются платформы Zoom и&nbsp;Skype, мессенджеры Whatsapp, Telegram, электронная почта, а&nbsp;также при необходимости могут быть задействованы иные платформы/площадки/мессенджеры.</p>
    <p class="p1"><i>Перечень материально-технического оборудования поштучно:</i></p>
    <p class="p3">(СТОЛ – 1 ШТ, СТУЛ – 1 ШТ, компьютер, методические материалы</p>
    <p class="p1"><i>Доступная среда:</i></p>
    <p class="p3">Образовательный центр ИП Суднеко А.М. не имеет специальных условий для обучения инвалидов и лиц с ограниченными возможностями здоровья</p>
    <p class="p1"><i><strong>Международное сотрудничество:</strong></i></p>
    <p class="p3">Заключенные и планируемые к заключению договоры с иностранными и международными организациями по вопросам образования и науки отсутствуют. Международной аккредитации образовательных программ не&nbsp;проводится.</p>
    <p class="p1"><i><strong>Документы:</strong></i></p>
    <p class="p5" v-for="doc in docs" :key="doc.url"><a target="_blank" rel="noopener noreferrer" :href="doc.url"><span class="s4">{{ doc.name }}</span><span class="s4 s8"></span></a></p>
    <p class="p1"><i><strong>Платные образовательные услуги:</strong></i></p>
    <p class="p5"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/Положение о порядке предоставления платных образовательных услуг.pdf"><span class="s4">Положение о порядке предоставления платных образовательных услуг.pdf</span><span class="s4 s8"></span></a></p>
    <p class="p5"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/Положение об основаниях и порядке снижения стоимости платных образовательных услуг.pdf"><span class="s4">Положение об основаниях и порядке снижения стоимости платных образовательных услуг.pdf</span><span class="s4 s8"></span></a></p>
    <p class="p5"><a target="_blank" rel="noopener noreferrer" href="https://annasudneko.pro/dist/docs/договор образец.docx"><span class="s4">договор образец.docx</span></a></p>
    <p class="p1"><i><strong>Финансово-хозяйственная деятельность:</strong></i></p>
    <p class="p3">Финансовое и&nbsp;материально-техническое обеспечение образовательной деятельности осуществляется за&nbsp;счет средств ИП Суднеко А.М.</p>
    <p class="p3">Образовательная деятельность, финансовое обеспечение которой осуществляется за&nbsp;счет бюджетных ассигнований федерального бюджета, за&nbsp;счет бюджетов субъектов Российской Федерации, за&nbsp;счет местных бюджетов, не&nbsp;ведется.</p>
    <p class="p3">Обучение осуществляется за&nbsp;счет средств физических и/или юридических лиц. Объем образовательной деятельности, финансовое обеспечение которой осуществляется по&nbsp;договорам об&nbsp;образовании за&nbsp;счет средств физических&nbsp;и (или) юридических лиц: 100%.</p>
    <p class="p2"><i><strong>Материально-техническое обеспечение и оснащенность образовательного процесса:</strong></i></p>
    <p class="p3">ноутбук – 1 шт, стол – 1 шт , стул 1 шт, методические материалы</p>
    <p class="p1"><i><strong>Вакантные места для приема (перевода) обучающихся:</strong></i></p>
    <p class="p3">Вакантные места для перевода не&nbsp;предусмотрены.</p>
    <p class="p3">Прием обучающихся ведется на&nbsp;основании и&nbsp;в&nbsp;соответствии с&nbsp;заключенными договорами об&nbsp;оказании платных образовательных услуг.</p>
    <p class="p1"><i><strong>Доступная среда:</strong></i></p>
    <p class="p3">Специальные условия для обучения инвалидов и&nbsp;лиц с&nbsp;ограниченными возможностями здоровья по&nbsp;зрению не&nbsp;предусмотрены и&nbsp;не&nbsp;имеются.</p>
    <p class="p3">Реализация дополнительных общеобразовательных (общеразвивающих) программ осуществляется с&nbsp;применением исключительно электронного обучения, дистанционных образовательных технологий.</p>
    <p class="p1"><i><strong>Организация питания в образовательной организации: </strong></i><span class="s1">не предусмотрено.</span></p>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
export default {
  name: "EduDocuments",
  components: {PageContainer},
  data() {
    return {
      docs: [],
    }
  },
  methods: {},
  mounted() {
    this.$server.request('user/docs', {}, (data) => this.docs = data.response);
  }
}
</script>

<style scoped>
 * {
   font-family: Arial, serif;
 }
 p {
   margin-bottom: 15px;
 }
</style>
