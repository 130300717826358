<template>
  <div class="item-select"
       :class="classes"
  >
    <div class="item-select-preview">
      <img :src="preview" alt="">
    </div>
    <div class="item-select-label">{{label}}</div>
  </div>
</template>

<script>
export default {
  name: "ItemSelect",
  props: ['classes', 'preview', 'label']
}
</script>

<style scoped lang="scss">
.item-select {
  //width: 90px;
  border-radius: 8px;
  cursor: pointer;
  max-width: 100%;

  &-preview {
    min-height: 220px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  &-label {
    text-align: center;
  }
  img {
    border-radius: 8px;
    height: auto;
    //width: auto;
  }
}
.decks-select .decks .item-select .item-select-preview {
  min-height: 80px;
  //height: 80px;
}
</style>
