<template>
  <div class="auth-container modal-container" @click="opened = false"
       :class="{'opened': opened}"
  >
    <div class="auth-wrapper modal-wrapper" @click.stop>
      <svg-icon
          class="close-modal"
          type="mdi"
          :path="mdiClose"
          size="22"
          @click="opened = false"
      />
      <div class="title">{{ promo.id ? 'Редактирование' : 'Создание' }} промокода</div>
      <div class="fields">
        Код
        <div style="font-size: 18px;font-weight: bold">{{ promo.code || 'Сгенерируется автоматически после создания' }}</div>
        Скидка в процентах
        <input type="text"
               class="email-field"
               v-model="promo.percent"
        >
        Срок действия (необязательно)
        <div>
          с <input type="date" class="email-field" v-model="promo.date_from">
          по <input type="date" class="email-field" v-model="promo.date_to">
        </div>
        Одноразовый
        <input style="width: 30px;" type="checkbox"
               class="email-field"
               v-model="promo.one_time"
        >
      </div>
      <div style="color: red" v-if="error">{{ error }}</div>
      <div class="auth-actions">
        <Button :text="promo.id ? 'Сохранить' : 'Создать'"
                @click="update"
                color="var(--primary-text-color)"
                text_color="#FFFFFF"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {mdiClose} from '@mdi/js';

export default {
  name: "PromoModal",
  data() {
    return {
      promo: {},
      opened: false,
      error: '',
      mdiClose
    }
  },
  methods: {
    get() {
      this.error = '';
      this.$server.request('promo/get/' + this.promo.id, {}, (data) => {
        this.promo = data.response;
        this.promo.one_time = !!(+this.promo.one_time);
      }, (data) => {
        this.error = data.error || 'Нет соединения с интернетом';
      })
    },
    update() {
      this.error = '';
      let params = {...this.promo};
      params.one_time = params.one_time ? 1 : 0;
      delete params.deleted;
      this.$server.request('promo/' + (+this.promo.id ? 'update/' + this.promo.id : 'create'), params, (data) => {
        this.opened = false;
        this.$eventBus.emit('promo-updated');
      }, (data) => {
        this.error = data.error || 'Нет соединения с интернетом';
      })
    },
    open(id) {
      this.promo = {id};
      if (+id) this.get();
      this.opened = true;
    }
  },
}
</script>

<style scoped lang="scss">
.auth-container {
  .auth-wrapper {
    .title {
      font-family: Arial;
      font-size: 24px;
    }
    .fields {
      display: flex;
      flex-direction: column;
      //margin-bottom: 16px;

      .change-label {
        margin-bottom: 4px;
        text-decoration: underline;
        cursor: pointer;
      }

      .email-field {
        margin-bottom: 16px;
      }
    }

    .auth-actions {
      display: flex;
      margin-top: 16px;
      justify-content: center;
    }
  }
}
</style>
