import { createApp } from 'vue'
import Vue from 'vue'

import App from './App.vue'
import store from "@/store";
import router from "@/router";
import SvgIcon from '@jamescoyle/vue-icon'
import $bus from './eventBus'
//components
import Button from "@/components/Button";
import Item from "@/components/Item";
import PageContainer from "@/components/PageContainer";
import moment from 'moment'
import 'viewerjs/dist/viewer.css'
import VueViewer from "v-viewer";
moment.locale('ru')
import {Server} from "@/server";
const app = createApp(App)
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$eventBus = $bus
app.config.globalProperties.$store = store
app.config.globalProperties.$server = new Server()
app.use(router)
app.use(VueViewer)
app.use(store)
app.component('Button', Button)
app.component('Item', Item)
app.component('svg-icon', SvgIcon)
app.component('page-container', PageContainer)
app.mount('#app');

