<template>
  <page-container style="max-width: 900px">
    <div class="title">Навигация</div>
    <p>ШАГ 1.</p>
    <p>Ознакомьтесь с офертой, пользовательским соглашением и программой необходимой вам игры или курса.</p>
    <p>ШАГ 2.</p>
    <p>Оплата. Внимание! При оплате указывайте тот email, которым будете пользоваться для входа на платформу.</p>
    <p>Если вы покупали игру через другой магазин, время подключения может занять сутки.</p>
    <p>ШАГ 3.</p>
    <p>Введите свой email в личном кабинете (верхний правый угол, при нажатии появится окошко с надписью "авторизация") и нажмите "получить код"</p>
    <p>ШАГ 4.</p>
    <p>Откройте вашу электронную почту, получите код в письме</p>
    <p>ШАГ 5.</p>
    <p>Введите проверочный код в окне авторизации</p>
    <p>ШАГ 6.</p>
    <p>Для игр:<br>Зайдите в раздел "Т-игры" или "Магазин", выберете купленную игру и нажмите "Новый сеанс".</p>
    <p>Для мастер-классов и обучения:<br>Если вы покупали курс, то он открывается после оплаты в соответствующем разделе (обучение/далее купленный курс). Не ждите письма с ссылкой. Открывайте купленный курс в соответствующем разделе после авторизации и покупки. Курс открывается тогда, когда вы его оплатили и авторизованы на сайте (шаги 3-5).</p>
    <p>ШАГ 7.</p>
    <p>В играх для тарифа "продвинутый" можно воспользоваться опцией "ссылка на игру". Вы заходите в игру и копируете ссылку наверху, которую можно послать участникам для доступа в игру.</p>
    <p>Внимание!</p>
    <p>Ссылка на игру работает только когда ведущий находится в игре. Ограничения по количеству игроков - 8 человек по ссылке.</p>
    <p>Ссылка действует 3 часа, если игра не закончилась, сформируйте новую ссылку. Для того, чтобы вернуться к полю с уже выложенными фишками и картами, нажмите "предыдущий сеанс". У каждого игрока сохраняется свой индивидуальный сеанс.</p>
    <p>Перед играми рекомендуется заранее быть авторизованным на сайте и не выходить из личного кабинета для того, чтобы возможные сбои или задержки при рассылке писем не помешали вашим планам. Сервис платформы не требует постоянной авторизации, поэтому можно не выходить из личного кабинета после каждой игры.</p>
    <Contacts/>
  </page-container>
</template>

<script>
import PageContainer from "@/components/PageContainer";
import Contacts from "@/components/Contacts";
export default {
  name: "Instructions",
  components: {Contacts, PageContainer},
  mounted() {
    document.getElementById('app').classList.add('hide_bg');
  },
  beforeUnmount() {
    document.getElementById('app').classList.remove('hide_bg');
  }
}
</script>

<style scoped>

</style>
