<template>
  <ul class="menu-list"
      :class="{'rowed': $router.currentRoute.value.path !== '/'}"
  >
    <li class="menu-list__item"
        v-for="(menu_item, i) in menu_list.filter(m=>+$store.state.user.admin || !m.admin)"
        :key="i"
    >
      <a class="button" v-if="menu_item.link.substring(0, 4)==='http'" target="_blank"
         :href="menu_item.link">{{ menu_item.title }}</a>
      <router-link v-else class="button" :class="{'router-link-active': $route.fullPath.indexOf(menu_item.link)===0}" :to="menu_item.link">{{ menu_item.title }}</router-link>
    </li>
  </ul>
</template>

<script>
export default {
  name: "Subnavbar",
  data() {
    return {
      menu_list: [
        {title: 'Автор', link: '/about'},
        // {title: 'Обучение', link: 'https://sand4self.ru/avtorskie-produkty'},
        {title: 'Обучение', link: '/courses'},
        // {title: 'Метафорические карты', link: '/meta_cards'},
        {title: 'T-игры', link: '/games/all'},
        {title: 'Кубики', link: '/cubes'},
        // {title: 'Ведущие', link: 'https://sand4self.ru/poleznaya-informaciya/vedushhie-igr'},
        {title: 'Карты', link: '/games/cards'},
        {title: 'Магазин', link: '/games/buy'},
        // {title: 'Контакты', link: '/contacts'},
        {title: 'Навигация', link: '/instructions'},
        {title: 'Админка', link: '/adminka/Клиенты', admin: 1},
      ],
      currentPath: '',
    }
  },
}
</script>

<style scoped lang="scss">
.menu-list {
  margin: 0 auto 32px;
  display: flex;
  align-items: center;
  order: 2;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  &.rowed {
    order: 1;
    flex-direction: row;
    .menu-list__item {
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__item {
    //margin-bottom: 16px;

    &:last-child {
      //margin-bottom: 0;
    }
  }
}
</style>
