<template>
  <div>
    <div class="controls">
      <input type="text" v-model="search" placeholder="Поиск по товару"/>
      <div style="display: flex;align-items: center;gap: 10px;">
        <label for="searchActivePromo">Только товары</label>
        <input id="searchActivePromo" type="checkbox" v-model="offline">
      </div>
    </div>
    <table class="adminTable">
      <thead>
      <th>№ заказа</th>
      <th>Дата</th>
      <th>Обработан</th>
      <th>Товары</th>
      <th>Цена</th>
      <th>Клиент</th>
      <th>Адрес</th>
      </thead>
      <tbody>
      <tr v-for="item in items.filter(p=>(!search.trim() || p.products_html.indexOf(search.trim())>-1) && (!offline || p.is_offline))"
          :key="item.id">
        <td>{{item.id}}</td>
        <td>{{item.datetime}}</td>
        <td>
          <span class="mr-2">{{ item.is_offline ? (item.to_process ? 'Нет' : 'Да') : '' }}</span>
          <Button v-if="item.to_process" class="small" @click="process(item.id)" text="Обработать"/>
        </td>
        <td v-html="item.products_html"></td>
        <td>{{ item.price }} ₽</td>
        <td>{{ item.client_info }}</td>
        <td><div style="max-width: 300px;">{{ item.address }}</div></td>
      </tr>
      </tbody>
    </table>
    <ProductModal @update="load" ref="ProductModal"/>
  </div>
</template>

<script>

import ProductModal from "@/pages/admin/product/Product.vue";

export default {
  name: "OrdersTable",
  components: {ProductModal},
  data() {
    return {
      search: '',
      offline: false,
      items: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$server.request('orders/get', {}, (data) => {
        this.items = data.response;
      });
    },
    process(id) {
      if (!confirm('Заказ обработан?')) return;
      this.$server.request('orders/update/' + id, {processed: 1}, () => {
        this.load();
      });
    },
  },
}
</script>

<style scoped>

</style>
