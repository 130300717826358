<template>
  <div>
    <input style="display: none" @change="handleFileUpload" ref="input" type="file"/>
    <div v-if="error">{{ error }}</div>
    <div v-if="uploading">
      {{ uploadInfo.percent }}%, {{ Math.round(uploadInfo.speed / 1024 / 1024 * 100) / 100 }} МБ/c,
      {{ uploadInfo.timeLeftStr }}
    </div>
    <div v-if="path">✓ Файл загружен: {{ path }}</div>
    <Button v-if="!uploading" style="height: 16px;font-size: 14px;margin-bottom: 10px" @click="$refs.input.click()"
            :text="value?'Изменить файл':'Выбрать файл'"/>
    <div v-if="uploading" class="progressBar">
      <div :style="`width: ${uploadInfo.percent}%`"></div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button";

export default {
  name: "LargeFileInput",
  components: {Button},
  props: {
    value: {default: '', type: String},
  },
  data() {
    return {
      uploadInfo: {
        total: 0,
        uploaded: 0,
        percent: 0,
        timeStart: 0,
      },
      uploading: false,
      path: this.value,
      error: '',
    }
  },
  methods: {
    async handleFileUpload() {
      const file = this.$refs.input.files[0];
      if (!file) {
        this.error = 'Файл не выбран';
        return;
      }
      this.$emit('file-selected', file);
      this.uploadInfo.total = 0;
      this.uploadInfo.uploaded = 0;
      this.uploadInfo.percent = 0;
      this.uploadInfo.timeStart = Date.now();
      this.error = '';
      this.path = '';
      this.uploading = true;
      const chunkSize = 1024 * 1024;
      // const chunkSize = 16 * 1024;
      let start = 0;
      let chunksNumber = Math.ceil(file.size / chunkSize);
      let chunkIndex = 0;
      let data;
      this.uploadInfo.total = file.size;
      let parts = file.name.split('.');
      let filename = Date.now() + '.' + parts[parts.length - 1];
      while (start < file.size) {
        data = await this.uploadChunk(file.slice(start, start + chunkSize), chunkIndex, chunksNumber, filename);
        start += chunkSize;
        chunkIndex++;
        this.uploadInfo.uploaded = start < file.size ? start : file.size;
        this.uploadInfo.percent = Math.round(this.uploadInfo.uploaded / this.uploadInfo.total * 100);
        this.uploadInfo.timeLeft = this.uploadInfo.percent ? Math.round(((100 - this.uploadInfo.percent) / this.uploadInfo.percent * (Date.now() - this.uploadInfo.timeStart)) / 1000) : 0;
        let mins = Math.floor(this.uploadInfo.timeLeft / 60);
        let seconds = this.uploadInfo.timeLeft % 60;
        this.uploadInfo.speed = this.uploadInfo.uploaded / (Date.now() - this.uploadInfo.timeStart) * 1000;
        this.uploadInfo.timeLeftStr = `осталось ${this.addZero(mins)}:${this.addZero(seconds)}`;
        if (data.response) {
          this.path = data.response;
          this.$emit('change', this.path);
          this.uploading = false;
        }
      }
    },
    addZero(n) {
      return n > 9 ? n : '0' + n;
    },
    async uploadChunk(chunk, chunkIndex, chunksNumber, filename, retries = 3) {
      const formData = this.$server.getInitialFormData();
      formData.append('file', chunk);
      formData.append('chunkIndex', chunkIndex);
      formData.append('filename', filename);
      formData.append('chunksNumber', chunksNumber);

      try {
        const response = await fetch(this.$server.url + 'course/upload', {
          method: 'POST',
          body: formData,
        });
        return await response.json();
      } catch (error) {
        if (retries > 0) {
          await this.uploadChunk(chunk, chunkIndex, chunksNumber, filename, retries - 1);
        } else {
          console.error('Failed to upload chunk: ', error);
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
.progressBar {
  border: 1px solid var(--primary-text-color);
  border-radius: 10px;

  div {
    background: var(--primary-text-color);
    height: 10px;
  }
}
</style>
