<template>
  <div>
    <div class="controls">
      <input type="text" v-model="search" placeholder="Поиск по товару"/>
      <div style="display: flex;align-items: center;gap: 10px;">
        <label for="searchActivePromo">Только опубликованные</label>
        <input id="searchActivePromo" type="checkbox" v-model="searchActive">
      </div>
      <Button style="width: fit-content" @click="$refs.ProductModal.open(0)" text="+ Добавить товар"/>
      <ProductModal ref="ProductModal"/>
    </div>
    <table class="adminTable">
      <thead>
      <th>Товар</th>
      <th>Цена</th>
      <th>Описание</th>
      <th>Опубликован</th>
      <th></th>
      </thead>
      <tbody>
      <tr v-for="item in items.filter(p=>(!search.trim() || p.title.indexOf(search.trim())>-1) && (!searchActive || +p.published))"
          :key="item.id">
        <td>{{ item.title }}</td>
        <td>{{ item.price }} ₽</td>
        <td><div style="max-width: 300px;white-space: nowrap;text-overflow: ellipsis;overflow: hidden">{{ item.description }}</div></td>
        <td>{{ +item.published ? 'Да' : 'Нет' }}</td>
        <td>
          <div style="display: flex;gap: 5px">
            <Button class="small" @click="$refs.ProductModal.open(item.id)" text="Редактировать"/>
            <Button v-if="+item.published" class="small" @click="remove(item.id)" text="Выключить"/>
            <Button v-else class="small" @click="activate(item.id)" text="Включить"/>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
    <ProductModal @update="load" ref="ProductModal"/>
  </div>
</template>

<script>

import ProductModal from "@/pages/admin/product/Product.vue";

export default {
  name: "ProductTable",
  components: {ProductModal},
  data() {
    return {
      search: '',
      searchActive: false,
      items: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$server.request('product/get', {admin: 1}, (data) => {
        this.items = data.response;
      });
    },
    remove(id) {
      this.$server.request('product/delete/' + id, {}, () => {
        this.load();
      });
    },
    activate(id) {
      this.$server.request('product/update/' + id, {published: 1}, () => {
        this.load();
      });
    },
  },
}
</script>

<style scoped>

</style>
