<template>
  <div :style="`width: ${size}px;height: ${size}px`"><span style="font-size: 24px">{{ title }}</span></div>
</template>

<script>
export default {
  name: "AddBlock",
  props: {
    title: {default: '', type: String},
    size: {default: '300', type: String},
  },
}
</script>
<style scoped lang="scss">
div {
  border: 2px dashed var(--primary-text-color);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
