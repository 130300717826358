<template>
  <div class="confirm-container modal-container"
       :class="{'opened': opened}"
  >
    <div class="confirm-wrapper modal-wrapper">
      <svg-icon
          class="close-modal"
          type="mdi"
          :path="mdiClose"
          size="22"
          @click="opened = false"
      />
      <div class="title">Вы действительно хотите очистить поле?</div>
      <div class="confirm-actions">
        <Button text="Да"
                @click="reload"
                color="var(--primary-text-color)"
                text_color="#FFFFFF"
        />
        <Button text="Нет"
                @click="opened = false"
                color="var(--primary-text-color)"
                text_color="#FFFFFF"
        />
      </div>
    </div>
  </div>

</template>

<script>
import {mdiClose} from '@mdi/js';

export default {
  name: "ConfirmClear",
  data() {
    return {
      opened: false,
      mdiClose
    }
  },
  methods: {
    reload() {
      window.location.reload();
    }
  },
  created() {
    this.$eventBus.on('open-confirm-clear', ()=>{this.opened = true})
  }
}
</script>

<style scoped lang="scss">
.confirm-actions {
  display: flex;
  .button {
    margin-right: 16px;
    flex-grow: 1;
    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
