<template>
  <router-link class="game"
               :key="type+product.id" :to="link">
    <div class="game-cover" :style="{width:size+'px',height:size+'px'}">
      <img :src="product.preview || product.image" style="border-radius: 10px" alt="">
      <div v-if="product.author && product.author.name" style="background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 15px;
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;">{{ game.author.name }}
      </div>
    </div>
    <div class="game-label card-label" style="width: 200px">{{ product.title }}</div>
  </router-link>
</template>

<script>

export default {
  name: "ProductCard",
  props: {
    product: {type: Object},
    type: {type: String},
    size: {type: Number, default: 300},
  },
  computed: {
    link() {
      if (this.type === 'games') return `/games/${this.product.id}/info`;
      else if (this.type === 'courses') return `/courses/${this.product.id}`;
      else if (this.type === 'products') return `/products/${this.product.id}`;
      return '';
    }
  },
  data() {
    return {}
  },
}
</script>
