<template>
  <div v-html="text" class="button" :class="{disabled}"
       :style="`background: ${color}; color: ${text_color}`"
  >
  </div>
</template>

<script>
export default {
  name: "Button",
  // props: ['text', 'color', 'text_color'],
  props: {
    text: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    },
    color: {
      default: 'var(--primary-text-color)',
      type: String
    },
    text_color: {
      default: '#FFFFFF',
      type: String
    },
  }
}
</script>
<style scoped lang="scss">
.button{
  &.disabled {
    pointer-events: none;
    opacity: 0.6;
  }
  height: auto;
  &.small {
    padding: 4px 8px;
    background: var(--primary-text-color);
    font-size: 14px;
  }
}
</style>
