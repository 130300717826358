<template>
  <page-container>
    <div class="title">{{ title }}</div>
    <div v-if="$route.params.type==='buy'" style="display: flex;gap: 10px;margin-bottom: 20px">
      <router-link :to="`/games/buy/${index}/${$route.params.tab || ''}`" class="button" v-for="(tab, index) in types"
                   :class="{'router-link-active': index===($route.params.online || 0)}" :key="index">
        {{ tab }}
      </router-link>
    </div>
    <div v-if="$route.params.type==='buy' && !(+$route.params.online) && tabs.length > 1"
         style="display: flex;gap: 10px;margin-bottom: 20px">
      <router-link :to="`/games/buy/${$route.params.online || 0}/${index || ''}`" class="button"
                   v-for="(tab, index) in tabs"
                   :class="{'router-link-active': index===($route.params.tab || 0)}" :key="index">
        {{ tab }}
      </router-link>
    </div>
    <div class="games-list">
      <template v-if="filteredGames.length">

        <router-link class="game" v-for="game in filteredGames"
                     :key="game.id" :to="+$route.params.online?`/products/${game.id}`:`/games/${game.id}/info`">
          <div class="game-cover">
            <img :src="game.back || game.image" style="border-radius: 10px" alt="">
            <div v-if="game.author && $route.params.tab" style="background: rgba(255, 255, 255, 0.8);
    position: absolute;
    bottom: 15px;
    padding: 5px 10px;
    border-radius: 0 5px 5px 0;">{{ game.author.name }}
            </div>
          </div>
          <div class="game-label card-label">{{ game.title }}</div>
        </router-link>
      </template>
      <div style="font-size: 22px" v-else-if="games">На данный момент доступных игр нет</div>
      <div style="font-size: 22px" v-else>Загрузка...</div>
    </div>
  </page-container>
</template>

<script>
export default {
  name: "Games",
  data() {
    return {
      games: null,
      types: ['Онлайн игры', 'Оффлайн игры'],
      tabs: ['Анна Суднеко'/*, 'Другие авторы'*/],
      loading: false,
    }
  },
  watch: {
    '$route.params.online'() {
      this.getGames();
    },
    '$route.params.type'() {
      this.getGames();
    }
  },
  methods: {
    getGames() {
      this.games = null;
      if (+this.$route.params.online) {
        this.$server.request('product/get', {}, data => this.games = data.response);
      } else {
        this.$server.request('game/get', {section: this.$route.params.type == 'cards' ? 'cards' : ''}, data => this.games = data.response);
      }
    },
  },
  computed: {
    filteredGames() {
      if (!this.games) return [];
      if (+this.$route.params.online) return this.games;
      return this.games.filter(g => {
        if (this.$route.params.type !== 'buy') return +g.author.id === 1;
        else return +g.price && (!this.$route.params.tab && +g.author.id === 1 || this.$route.params.tab && +g.author.id !== 1);
      });
    },
    title() {
      if (this.$route.params.type == 'cards') return 'Метафорические карты';
      return this.$route.params.type !== 'buy' ? 'Психологические игры' : 'Магазин психологических игр'
    }
  },
  mounted() {
    this.getGames()
  },
}
</script>
