<template>
  <div class="page-container">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "PageContainer"
}
</script>

<style scoped lang="scss">
  .page-container {
    background: var(--element-bg);
    border-radius: 8px;
    padding: 20px;
    order: 2;
    margin: 0 auto;
    min-height: 50vh;
    min-width: calc(100vw - 120px);
  }
</style>
