<template>
    <div>
      «{{ game.title }}»:<br>
      {{game.bg_colors.length ===1?('поле'+(game.fields_count > 1 ? ` - ${game.fields_count}шт` : '')):`поля разных цветов - ${game.bg_colors.length} шт`}}{{ game.fields_count > 1 ? ` - ${game.fields_count}шт` : '' }},<br>
      <div v-if="+game.cards_count">
        {{ +game.cards_count > 1 ? `дополнительные карты ведущего ${game.cards_count}шт,` : `одна дополнительная карта ведущего,` }}
      </div>
      <div v-html="genCards(game)"></div>
    </div>
</template>

<script>

export default {
  name: "GameEquipment",
  props:{
    game: {
      type: Object,
    }
  },
  methods: {
    genCards(game) {
      let decks = [];
      game.decks_buy.forEach(d => {
        let deck = game.decks.find(d1 => d1.id == d);
        decks.push(`карты «${deck.title}» ${deck.number}шт`);
      })
      return decks.join(',<br>');
    },
  }
}
</script>
