<template>
  <div class="content-container">
    <Subnavbar/>
    <router-view/>
    <Auth/>
    <ConfirmClear/>
    <div class="footer">
      <div style="display: flex;align-items: center;">Все права защищены. © Анна Суднеко, {{ $moment().format('YYYY') }}</div>
      <div class="footer_links">
        <div style="display: flex;flex-direction: column">
          <router-link to="/offer">Оферта</router-link>
          <router-link to="/program">Программа</router-link>
        </div>
        <router-link to="/agreement">Пользовательское соглашение</router-link>
        <router-link to="/edu_documents">Сведения об обр. организации</router-link>
      </div>
    </div>
    <img class="background"
         src="/img/descktop_bg.jpg">
  </div>
</template>

<script>
import Subnavbar from "@/components/Subnavbar";
import Auth from "@/pages/Auth";
import ConfirmClear from "@/pages/psycho/ConfirmClear";

export default {
  name: "Container",
  components: {
    ConfirmClear,
    Subnavbar,
    Auth,
  },
  created() {
  }
}
</script>
<style lang="scss">
.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover
}

#app.in_game {
  .content-container {
    background-image: none;
    padding: 0;
    height: 100vh;
    background-color: #3b89b5;
  }

  .background, .footer {
    display: none;
  }

  .menu-list, .navbar {
    display: none;
  }
}

#app.hide_bg {
  .content-container {
    background-image: none;
  }
}
</style>
<style scoped lang="scss">
.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: calc(100% - 160px);
  padding: 10px 80px;
  background: #b3d0e2;
  border-top: 1px solid #3b89b5;
  display: flex;
  justify-content: space-between;
  z-index: 999999;
}

.footer_links {
  display: inline-flex;
  gap: 20px;
  > * {
    flex: 1;
  }
  a {
    text-decoration: none
  }
}

.content-container {
  display: flex;
  flex-direction: column;
  //background-size: contain;
  //background-repeat: no-repeat;
  //background-image: url("/img/descktop_bg.jpg");
  //background-color: #3b89b5;
  //background-position: top;
  position: relative;
  padding-top: var(--navbar-height);
  padding-bottom: 60px;
  min-height: calc(100vh - var(--navbar-height) - 60px);
  //overflow: hidden;
  padding-left: 40px;
  padding-right: 40px;
}

@media (max-width: 768px) {
  .content-container {
    background-image: url("/img/main_bg.jpeg");
    background-size: cover;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 80px;
  }
  .footer {
    padding: 10px 20px;
    width: calc(100% - 40px);
    flex-direction: column-reverse;
    gap: 10px;

    .footer_links {
      gap: 10px
    }
  }
}
</style>
